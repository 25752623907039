/* eslint-disable prettier/prettier */
import { map } from 'lodash';
// import axios from 'src/utils/axios';
// import axios from 'axios';
import axios from '../../utils/axiosInstanse';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { RSLUrl } from 'src/config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  carMakeFareData: []
};

const { webLive_URL, webAPI_URL } = RSLUrl;

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // hasError(state, action) {
    //   state.isLoading = false;
    //   state.errorData = {
    //     source: action.payload.source, // action.payload should now include a 'source' property
    //     error: action.payload.error
    //   };
    // },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // Reset Password
    getResetLoginFormSuccess(state, action) {
      state.isLoading = false;
      state.resetLoginResponse = action.payload;
    },
    changeUserPasswordSuccess(state, action) {
      state.isLoading = false;
      state.changeUserPassResponse = action.payload;
    },
    getUserProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfileData = action.payload;
    },
    updateUserProfileSuccess(state, action) {
      state.isLoading = false;
      state.updateUserProfileResponse = action.payload;
    },

    //driver status

    getPassengerDetailSuccess(state, action) {
      state.isLoading = false;
      state.passengerDetailList = action.payload;
    },

    getTopDriverInfoSucess(state, action) {
      state.isLoading = false;
      state.topDriverInfoData = action.payload;
    },

    // TaxiDispatch start
    getTaxiDispatchSuccess(state, action) {
      state.isLoading = false;
      state.taxiDipacthList = action.payload;
    },
    getAllDriverStatusSuccess(state, action) {
      state.isLoading = false;
      state.allDriverStatusData = action.payload;
    },
    // TaxiDispatch end

    //supervisor

    getSupervisorInfoSucess(state, action) {
      state.isLoading = false;
      state.supervisorData = action.payload;
    },
    getSupervisorInfoCSVSucess(state, action) {
      state.isLoading = false;
      state.supervisorCSVData = action.payload;
    },
    getSupervisorDetailsSucess(state, action) {
      state.isLoading = false;
      state.supervisorDetailsDatas = action.payload;
    },
    addSupervisorSucess(state, action) {
      state.isLoading = false;
      state.addSupervisorRes = action.payload;
    },
    editSupervisorSucess(state, action) {
      state.isLoading = false;
      state.editSupervisorRes = action.payload;
    },
    unAssignSupervisorSucess(state, action) {
      state.isLoading = false;
      state.unAssignSupervisorRes = action.payload;
    },
    unpdateSuperVisorStatusSucess(state, action) {
      state.isLoading = false;
      state.updateDriverStatusRes = action.payload;
    },
    getSearchTripIdSucess(state, action) {
      state.isLoading = false;
      state.searchTripIdData = action.payload;
    },
    getSupervisorDetailsInfoSucess(state, action) {
      state.isLoading = false;
      state.supervisorDetailsData = action.payload;
    },
    getManageSupervisorInfoSucess(state, action) {
      state.isLoading = false;
      state.supervisorInfoDatas = action.payload;
    },
    getPushNotificationInfoSucess(state, action) {
      state.isLoading = false;
      state.pushNotification = action.payload;
    },
    getPushNotificationInfo1Sucess(state, action) {
      state.isLoading = false;
      state.pushNotification1 = action.payload;
    },
    getPassengerDataSucess(state, action) {
      state.isLoading = false;
      state.PassengerData = action.payload;
    },
    getSupervisorMonitorLogSucess(state, action) {
      state.isLoading = false;
      state.supervisorMonitorLogData = action.payload;
    },
    getSupervisorMonitorSucess(state, action) {
      state.isLoading = false;
      state.supervisorMonitorData = action.payload;
    },
    getCustomZoneSucess(state, action) {
      state.isLoading = false;
      state.customZoneData = action.payload;
    },
    getCustomZoneAllSucess(state, action) {
      state.isLoading = false;
      state.customZoneAllData = action.payload;
    },
    getPassengerData1Sucess(state, action) {
      state.isLoading = false;
      state.passengerAllData = action.payload;
    },
    getPassengerPushAllSucess(state, action) {
      state.isLoading = false;
      state.passengerPushAllData = action.payload;
    },
    getPassengerDetailsSucess(state, action) {
      state.isLoading = false;
      state.getByPassengerData = action.payload;
    },

    //kiosk
    getKioskListSucess(state, action) {
      state.isLoading = false;
      state.kioskData = action.payload;
    },
    getKioskListByIdSucess(state, action) {
      state.isLoading = false;
      state.kioskListByData = action.payload;
    },
    getKioskDataUpdateSucess(state, action) {
      state.isLoading = false;
      state.kioskUpdateData = action.payload;
    },

    //new booking
    getCompanyListSucess(state, action) {
      state.isLoading = false;
      state.companyAllList = action.payload;
    },
    getCompanyListAllSucess(state, action) {
      state.isLoading = false;
      state.companyListAll = action.payload;
    },
    getCarListSucess(state, action) {
      state.isLoading = false;
      state.carAllList = action.payload;
    },
    getAllManufacturerListSuccess(state, action) {
      state.isLoading = false;
      state.allManufacturerListData = action.payload;
    },
    getCompanyManufacturerListSuccess(state, action) {
      state.isLoading = false;
      state.companyManufacturerLisData = action.payload;
    },
    getCompanyMakeListSuccess(state, action) {
      state.isLoading = false;
      state.companyMakeListData = action.payload;
    },
    getCarListFilterSucess(state, action) {
      state.isLoading = false;
      state.carAllFilterList = action.payload;
    },
    getCarMakeListSucess(state, action) {
      state.isLoading = false;
      state.carMakeAllList = action.payload;
    },
    getCorporateListSucess(state, action) {
      state.isLoading = false;
      state.corporateAllList = action.payload;
    },
    getCompanyCorporateListSucess(state, action) {
      state.isLoading = false;
      state.companyCorporateAllList = action.payload;
    },
    getUpcomingBookingSucess(state, action) {
      state.isLoading = false;
      state.upcomingBookingList = action.payload;
    },
    getBookingPriceSucess(state, action) {
      state.isLoading = false;
      state.bookingPriceUpdate = action.payload;
    },
    getCancelBookingSucess(state, action) {
      state.isLoading = false;
      state.cancelBookingData = action.payload;
    },
    getByPassengerSucess(state, action) {
      state.isLoading = false;
      state.passengerDetails = action.payload;
    },
    getByPassengerTransactionSucess(state, action) {
      state.isLoading = false;
      state.passengerTransactionDetails = action.payload;
    },
    getByVerifySucess(state, action) {
      state.isLoading = false;
      state.verifyDetails = action.payload;
    },
    getB2bandB2CSucess(state, action) {
      state.isLoading = false;
      state.b2bAndB2cSummaryDatas = action.payload;
    },
    getPastBookingSummarySucess(state, action) {
      state.isLoading = false;
      state.pastBookingSummaryDatas = action.payload;
    },
    getByCarSucess(state, action) {
      state.isLoading = false;
      state.carDetails = action.payload;
    },
    getByCarMakeSucess(state, action) {
      state.isLoading = false;
      state.carMakeDetails = action.payload;
    },
    getByPassengerCardSucess(state, action) {
      state.isLoading = false;
      state.passengerCardData = action.payload;
    },
    getByEditUpdateSucess(state, action) {
      state.isLoading = false;
      state.editDatas = action.payload;
    },
    getByCorporateUpdateSucess(state, action) {
      state.isLoading = false;
      state.corporateDatas = action.payload;
    },
    getByEditCorporateSucess(state, action) {
      state.isLoading = false;
      state.corporateDatas = action.payload;
    },
    getByNearestDriverSucess(state, action) {
      state.isLoading = false;
      state.nearestDriverList = action.payload;
    },

    getsubscriptionDriverListSuccess(state, action) {
      state.isLoading = false;
      state.subscriptionDriverList = action.payload;
    },
    
    addSubscriptionDriverSuccess(state, action) {
      state.isLoading = false;
      state.addSubscriptionDriverRes = action.payload;
    },
    getByActionDriverDataSucess(state, action) {
      state.isLoading = false;
      state.actionDriverList = action.payload;
    },
    getExitRecordInfoSucess(state, action) {
      state.isLoading = false;
      state.exitRecord = action.payload;
    },
    getByAddPassengerSucess(state, action) {
      state.isLoading = false;
      state.insertPassenger = action.payload;
    },
    getByEditPassengerSucess(state, action) {
      state.isLoading = false;
      state.updatePassenger = action.payload;
    },
    getDriverDocumentSucess(state, action) {
      state.isLoading = false;
      state.driverDocumentList = action.payload;
    },
    getByPassengerDataSucess(state, action) {
      state.isLoading = false;
      state.passengerRecordList = action.payload;
    },
    getDriverFilterDocumentSucess(state, action) {
      state.isLoading = false;
      state.driverFilterDocumentList = action.payload;
    },
    getDriverFilterDocumentStatusSucess(state, action) {
      state.isLoading = false;
      state.driverFilterDocumentStatusData = action.payload;
    },
    getByCorporateDataSucess(state, action) {
      state.isLoading = false;
      state.corporateRecordList = action.payload;
    },
    getByPackageDataSucess(state, action) {
      state.isLoading = false;
      state.packageRecordList = action.payload;
    },
    getByMotorCarListSucess(state, action) {
      state.isLoading = false;
      state.motorList = action.payload;
    },
    getCarModelListSucess(state, action) {
      state.isLoading = false;
      state.carModelList = action.payload;
    },
    getByFareDetailsSucess(state, action) {
      state.isLoading = false;
      state.fareAllDetails = action.payload;
    },
    getAllCarMakesSucess(state, action) {
      state.isLoading = false;
      state.allCarMakesData = action.payload;
    },
    getCarMakeFareSucess(state, action) {
      state.isLoading = false;
      state.carMakeFareData = action.payload;
    },
    getByFareDetailsCarMakeSucess(state, action) {
      state.isLoading = false;
      state.fareAllCarMakeDetails = action.payload;
    },
    getByCorporateDetailsSucess(state, action) {
      state.isLoading = false;
      state.corporateAllDetails = action.payload;
    },
    getBySaveBookingSucess(state, action) {
      state.isLoading = false;
      state.saveBookingResponse = action.payload;
    },
    getByAssignDriverDataSucess(state, action) {
      state.isLoading = false;
      state.assignDriverData = action.payload;
    },
    getByOverViewSucess(state, action) {
      state.isLoading = false;
      state.overviewAllData = action.payload;
    },
    getByEndTripSucess(state, action) {
      state.isLoading = false;
      state.endTripData = action.payload;
    },
    getByCurrentDriverLocationSucess(state, action) {
      state.isLoading = false;
      state.currentDriverLocation = action.payload;
    },
    getLocationGroupSucess(state, action) {
      state.isLoading = false;
      state.locationListAll = action.payload;
    },
    getLocationTransactionSucess(state, action) {
      state.isLoading = false;
      state.locationTransactionData = action.payload;
    },
    getAddRecordInfoSucess(state, action) {
      state.isLoading = false;
      state.locationGroupInfo = action.payload;
    },
    getEditRecordInfoSucess(state, action) {
      state.isLoading = false;
      state.editLocationGroupInfo = action.payload;
    },
    getLocationStatusUpdateSucess(state, action) {
      state.isLoading = false;
      state.statusLocationGroupInfo = action.payload;
    },
    getChatListSucess(state, action) {
      state.isLoading = false;
      state.chatRecords = action.payload;
    },
    getChatListUpdateSucess(state, action) {
      state.isLoading = false;
      state.chatRecordsUpdate = action.payload;
    },
    getSendDriverSmsSuccess(state, action) {
      state.isLoading = false;
      state.msgSendResponce = action.payload;
    },
    getSendDriverMailSuccess(state, action) {
      state.isLoading = false;
      state.mailSendResponce = action.payload;
    },
    getManageDriverListSuccess(state, action) {
      state.isLoading = false;
      state.driverList = action.payload;
    },
    getTaxiListSuccess(state, action) {
      state.isLoading = false;
      state.taxiListData = action.payload;
    },
    getLocationQueueDriverListSuccess(state, action) {
      state.isLoading = false;
      state.locationQueueDriverListData = action.payload;
    },

    getAddManageDriverSuccess(state, action) {
      state.isLoading = false;
      state.addManageDriverRes = action.payload;
    },
    getEditManageDriverSuccess(state, action) {
      state.isLoading = false;
      state.editManageDriverRes = action.payload;
    },
    getDriverGetByIdSuccess(state, action) {
      state.isLoading = false;
      state.driverGetByData = action.payload;
    },
    getDriverFilterCallSuccess(state, action) {
      state.isLoading = false;
      state.filterValue = action.payload;
    },
    getDriverFilterCallCsvSuccess(state, action) {
      state.isLoading = false;
      state.filterValueCsv = action.payload;
    },
    getDriverFilterCountSuccess(state, action) {
      state.isLoading = false;
      state.filterCount = action.payload;
    },
    getSubscriptionListSuccess(state, action) {
      state.isLoading = false;
      state.subscriptionData = action.payload;
    },
    getUpdateDriverStatusSuccess(state, action) {
      state.isLoading = false;
      state.driverStatusUpdate = action.payload;
    },
    getApproveStatusSuccess(state, action) {
      state.isLoading = false;
      state.approveStatusUpdate = action.payload;
    },
    getCancelStatusSuccess(state, action) {
      state.isLoading = false;
      state.cancelStatusUpdate = action.payload;
    },
    getHoldStatusSuccess(state, action) {
      state.isLoading = false;
      state.holdStatusUpdate = action.payload;
    },
    getExportDriverListSuccess(state, action) {
      state.isLoading = false;
      state.driverExportData = action.payload;
    },
    getManageLostDataSuccess(state, action) {
      state.isLoading = false;
      state.lostFoundRecords = action.payload;
    },
    getDriverInformationSuccess(state, action) {
      state.isLoading = false;
      state.driverInfoData = action.payload;
    },
    getDriverLogoutSuccess(state, action) {
      state.isLoading = false;
      state.driverLogoutRes = action.payload;
    },
    getDriverCurrentInfoSuccess(state, action) {
      state.isLoading = false;
      state.driverCurrentInfoData = action.payload;
    },
    getZonesInfoSuccess(state, action) {
      state.isLoading = false;
      state.zonesInfoData = action.payload;
    },
    getChangDriverPasswordSuccess(state, action) {
      state.isLoading = false;
      state.driverPasswordRes = action.payload;
    },
    getShiftHistorySuccess(state, action) {
      state.isLoading = false;
      state.shiftreport = action.payload;
    },
    getReferralHistorySuccess(state, action) {
      state.isLoading = false;
      state.referraltreport = action.payload;
    },
    getCompleteTripListSuccess(state, action) {
      state.isLoading = false;
      state.completeTripData = action.payload;
    },
    getCarListAllSuccess(state, action) {
      state.isLoading = false;
      state.carAllListData = action.payload;
    },
    getCarListCsvAllSuccess(state, action) {
      state.isLoading = false;
      state.carAllListCsvData = action.payload;
    },
    getCorporateListSuccess(state, action) {
      state.isLoading = false;
      state.corporateListData = action.payload;
    },
    getZoneGroupSuccess(state, action) {
      state.isLoading = false;
      state.zoneGroupListData = action.payload;
    },
    getZoneGroupStatusSuccess(state, action) {
      state.isLoading = false;
      state.zoneGroupStatusRes = action.payload;
    },
    getZoneGroupCSVSuccess(state, action) {
      state.isLoading = false;
      state.zoneGroupListCSVData = action.payload;
    },
    getManageZoneListSuccess(state, action) {
      state.isLoading = false;
      state.manageZoneListData = action.payload;
    },
    getManageZoneListCsvSuccess(state, action) {
      state.isLoading = false;
      state.manageZoneListCsvData = action.payload;
    },
    getChangeZoneStatusSuccess(state, action) {
      state.isLoading = false;
      state.changeZoneStatusRes = action.payload;
    },
    getManageAddZoneSuccess(state, action) {
      state.isLoading = false;
      state.manageAddZoneRes = action.payload;
    },
    getCorporateListCSVSuccess(state, action) {
      state.isLoading = false;
      state.corporateListDataCSV = action.payload;
    },
    getCorporatePackageListSuccess(state, action) {
      state.isLoading = false;
      state.corporatePackageListData = action.payload;
    },
    getLocationQueueLogListSuccess(state, action) {
      state.isLoading = false;
      state.locationQueueData = action.payload;
    },
    getLocationEntryExitLogListSuccess(state, action) {
      state.isLoading = false;
      state.locationEntryExitData = action.payload;
    },
    getLocationQueueLogSuccess(state, action) {
      state.isLoading = false;
      state.locationQueueDataCSV = action.payload;
    },
    getPromocodeListSuccess(state, action) {
      state.isLoading = false;
      state.promocodeListData = action.payload;
    },
    getPromocodeListCSVSuccess(state, action) {
      state.isLoading = false;
      state.promocodeListDataCSV = action.payload;
    },
    getAddPromocodeSuccess(state, action) {
      state.isLoading = false;
      state.addPromocodeRes = action.payload;
    },
    getEditPromocodeSuccess(state, action) {
      state.isLoading = false;
      state.editPromocodeRes = action.payload;
    },
    getPromocodeInfoSuccess(state, action) {
      state.isLoading = false;
      state.promoCodeInfoData = action.payload;
    },
    getPromocodeServiceSuccess(state, action) {
      state.isLoading = false;
      state.promoCodeServiceDatas = action.payload;
    },
    // Custom zone list
    getCustomZoneListSuccess(state, action) {
      state.isLoading = false;
      state.customZoneListData = action.payload;
    },
    getUpdateCustomZoneStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateCustomZoneStatusRes = action.payload;
    },
    getCustomZoneAddSuccess(state, action) {
      state.isLoading = false;
      state.customZoneAddRes = action.payload;
    },
    getCustomZoneEditListSuccess(state, action) {
      state.isLoading = false;
      state.customZoneEditListData = action.payload;
    },
    getCustomZoneEditSuccess(state, action) {
      state.isLoading = false;
      state.customZoneEditRes = action.payload;
    },

    getCustomZoneDriverListSuccess(state, action) {
      state.isLoading = false;
      state.customZoneDriverListData = action.payload;
    },
    getSendPushNotificationToDriversSuccess(state, action) {
      state.isLoading = false;
      state.sendPushNotificationToRes = action.payload;
    },
    getCustomZoneDriverListsSuccess(state, action) {
      state.isLoading = false;
      state.customZoneDriverListsData = action.payload;
    },
    getDriverListDetailsSuccess(state, action) {
      state.isLoading = false;
      state.driverListsDatas = action.payload;
    },
    getCustomZoneModelFaresDetailsSuccess(state, action) {
      state.isLoading = false;
      state.customZoneModelFareDatas = action.payload;
    },
    getLocationCustomFareSuccess(state, action) {
      state.isLoading = false;
      state.locationCustomFareDatas = action.payload;
    },
    getLocationCustomFareAddEditSuccess(state, action) {
      state.isLoading = false;
      state.locationFareAddEditRes = action.payload;
    },
    getCustomFareAddEditSuccess(state, action) {
      state.isLoading = false;
      state.customZoneModelFareAddEditRes = action.payload;
    },
    getUpdateDriverListSuccess(state, action) {
      state.isLoading = false;
      state.updateDriverListRes = action.payload;
    },

    getCorporatePackageStatusSuccess(state, action) {
      state.isLoading = false;
      state.packageStatusRes = action.payload;
    },
    getAddCorporatePackageSuccess(state, action) {
      state.isLoading = false;
      state.addPackageRes = action.payload;
    },
    getEditCorporatePackageSuccess(state, action) {
      state.isLoading = false;
      state.editPackageRes = action.payload;
    },
    getPackageDetailSuccess(state, action) {
      state.isLoading = false;
      state.packageDetailData = action.payload;
    },
    // Passenger Package Start
    addPassengerPackageSuccess(state, action) {
      state.isLoading = false;
      state.addPassengerPackageRes = action.payload;
    },
    updatePassengerPackageSuccess(state, action) {
      state.isLoading = false;
      state.updatePassengerPackageRes = action.payload;
    },
    passengerPackageListSuccess(state, action) {
      state.isLoading = false;
      state.passengerPackageListDatas = action.payload;
    },
    passengerPackageListCSVSuccess(state, action) {
      state.isLoading = false;
      state.passengerPackageListCSVDatas = action.payload;
    },
    getPassengerPackageDetailSuccess(state, action) {
      state.isLoading = false;
      state.passengerPackageDetailsDatas = action.payload;
    },
    changePassengerPackageStatusSuccess(state, action) {
      state.isLoading = false;
      state.changePassengerPackageStatusRes = action.payload;
    },

    // Package passenger end

    // Sms template starts

    addSmsTemplateSuccess(state, action) {
      state.isLoading = false;
      state.smsTemplateAddEditRes = action.payload;
    },
    getSmsTemplateSuccess(state, action) {
      state.isLoading = false;
      state.smsTemplateDatas = action.payload;
    },
    getSmsTemplateListsSuccess(state, action) {
      state.isLoading = false;
      state.smsTemplateListsDatas = action.payload;
    },
    UpdateSmsTemplateStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateSmsTemplateRes = action.payload;
    },
    // Sms template End

    // Mail settings Start
    addMailSettingSuccess(state, action) {
      state.isLoading = false;
      state.addMailSettingRes = action.payload;
    },
    adminUpdateSmtpConfigSuccess(state, action) {
      state.isLoading = false;
      state.adminUpdateMailSettingRes = action.payload;
    },

    updateMailSettingSuccess(state, action) {
      state.isLoading = false;
      state.updateMailSettingRes = action.payload;
    },
    // Sms
    updateSmsSettingsSuccess(state, action) {
      state.isLoading = false;
      state.addSMSSettingRes = action.payload;
    },
    updateAdminSmsSettingsSuccess(state, action) {
      state.isLoading = false;
      state.updateAdminSMSSettingRes = action.payload;
    },
    addEditSocialNetworksSuccess(state, action) {
      state.isLoading = false;
      state.addEditSocialNetworksRes = action.payload;
    },
    updateSiteSettingsDetailSuccess(state, action) {
      state.isLoading = false;
      state.updateSiteSettingsDetailRes = action.payload;
    },
    getGoogleMapSettingsDetailSuccess(state, action) {
      state.isLoading = false;
      state.googleMapSettingsDetailData = action.payload;
    },
    updateGoogleMapSettingsSuccess(state, action) {
      state.isLoading = false;
      state.updateGoogleMapSettingsRes = action.payload;
    },
    getSmtpInfoSuccess(state, action) {
      state.isLoading = false;
      state.smtpInfoDatas = action.payload;
    },
    getSiteSettingsDetailSuccess(state, action) {
      state.isLoading = false;
      state.siteSettingsDetailDatas = action.payload;
    },
    getSocialSettingsDetailSuccess(state, action) {
      state.isLoading = false;
      state.socialSettingsDetailDatas = action.payload;
    },
    getAdminSmtpInfoSuccess(state, action) {
      state.isLoading = false;
      state.adminSmtpInfoDatas = action.payload;
    },
    getSmsInfoSuccess(state, action) {
      state.isLoading = false;
      state.smsInfoDatas = action.payload;
    },
    getAdminSmsInfoSuccess(state, action) {
      state.isLoading = false;
      state.adminSmsInfoDatas = action.payload;
    },
    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.countryListDatas = action.payload;
    },
    getCountryDetailSuccess(state, action) {
      state.isLoading = false;
      state.countryDetailDatas = action.payload;
    },
    getAddStateSuccess(state, action) {
      state.isLoading = false;
      state.addStateRes = action.payload;
    },
    getUpdateStateSuccess(state, action) {
      state.isLoading = false;
      state.updateStateRes = action.payload;
    },
    getStateDetailSuccess(state, action) {
      state.isLoading = false;
      state.stateDetailDatas = action.payload;
    },
    getStateListSuccess(state, action) {
      state.isLoading = false;
      state.stateListDatas = action.payload;
    },
    getCityListSuccess(state, action) {
      state.isLoading = false;
      state.cityListDatas = action.payload;
    },
    getChangeCityStatusSuccess(state, action) {
      state.isLoading = false;
      state.changeCityStatusRes = action.payload;
    },
    getAddCitySuccess(state, action) {
      state.isLoading = false;
      state.addCityRes = action.payload;
    },
    getCityDetailSuccess(state, action) {
      state.isLoading = false;
      state.getCityDatas = action.payload;
    },
    getUpdateCitySuccess(state, action) {
      state.isLoading = false;
      state.updateCityRes = action.payload;
    },
    getStateListsSuccess(state, action) {
      state.isLoading = false;
      state.stateListsDatas = action.payload;
    },
    getCityListsSuccess(state, action) {
      state.isLoading = false;
      state.cityListsDatas = action.payload;
    },
    manageCountryListSuccess(state, action) {
      state.isLoading = false;
      state.manageCountryListDatas = action.payload;
    },
    updateCountrySuccess(state, action) {
      state.isLoading = false;
      state.updateCountryRes = action.payload;
    },
    getChangeStateStatusSuccess(state, action) {
      state.isLoading = false;
      state.changeStateStatusRes = action.payload;
    },
    getCSCStatusSuccess(state, action) {
      state.isLoading = false;
      state.changeCSCStatusRes = action.payload;
    },
    getSmtpConfigListSuccess(state, action) {
      state.isLoading = false;
      state.smtpConfigListDatas = action.payload;
    },
    getSmsConfigListSuccess(state, action) {
      state.isLoading = false;
      state.smsConfigListDatas = action.payload;
    },
    // Mail settings Start

    getModelFareListSuccess(state, action) {
      state.isLoading = false;
      state.modelFareListDatas = action.payload;
    },
    getFareDetailSuccess(state, action) {
      state.isLoading = false;
      state.fareDetailDatas = action.payload;
    },
    addFareSuccess(state, action) {
      state.isLoading = false;
      state.addFareRes = action.payload;
    },
    updateFareSuccess(state, action) {
      state.isLoading = false;
      state.updateFareRes = action.payload;
    },
    getCorporateDetailSuccess(state, action) {
      state.isLoading = false;
      state.corporateDetailData = action.payload;
    },
    // Manage Location
    getLocationListSuccess(state, action) {
      state.isLoading = false;
      state.locationDetailData = action.payload;
    },
    getLocationDashSuccess(state, action) {
      state.isLoading = false;
      state.locationDashData = action.payload;
    },
    getLocationListCSVSuccess(state, action) {
      state.isLoading = false;
      state.locationDetailCSVData = action.payload;
    },
    getAddLocationSuccess(state, action) {
      state.isLoading = false;
      state.locationAddRes = action.payload;
    },
    getEditLocationSuccess(state, action) {
      state.isLoading = false;
      state.locationEditRes = action.payload;
    },
    getLocationDetailsSuccess(state, action) {
      state.isLoading = false;
      state.locationDetailsData = action.payload;
    },
    getAddDriverInQueueListSuccess(state, action) {
      state.isLoading = false;
      state.addDriverInLocationQueueListRes = action.payload;
    },
    getDriverDetailsInLocationQueueSuccess(state, action) {
      state.isLoading = false;
      state.locationQueueDriverDetailData = action.payload;
    },
    getSecondaryDriverImagesVerifySuccess(state, action) {
      state.isLoading = false;
      state.secondaryDriverImagesData = action.payload;
    },
    // Toll start
    getTollListsSuccess(state, action) {
      state.isLoading = false;
      state.tollListsData = action.payload;
    },
    getTollListsCSVSuccess(state, action) {
      state.isLoading = false;
      state.tollListsCSVData = action.payload;
    },
    getAddTollSuccess(state, action) {
      state.isLoading = false;
      state.addTollRes = action.payload;
    },
    getEditTollSuccess(state, action) {
      state.isLoading = false;
      state.editTollRes = action.payload;
    },
    getTollDetailssSuccess(state, action) {
      state.isLoading = false;
      state.tollDetailsData = action.payload;
    },
    getChangeTollStatusSuccess(state, action) {
      state.isLoading = false;
      state.tollStatusRes = action.payload;
    },
    // Toll End
    getAddDriverListSuccess(state, action) {
      state.isLoading = false;
      state.addDriverListRes = action.payload;
    },
    getDriverListSuccess(state, action) {
      state.isLoading = false;
      state.driverListData = action.payload;
    },
    getLocationDriverDetailsSuccess(state, action) {
      state.isLoading = false;
      state.locationDriverDetailData = action.payload;
    },
    getMultipleDropLocationListSuccess(state, action) {
      state.isLoading = false;
      state.multipleDropLocationListData = action.payload;
    },
    getAddMultipleDropLocationSuccess(state, action) {
      state.isLoading = false;
      state.addMultipleDropLocationRes = action.payload;
    },
    getDriverLocationListsSuccess(state, action) {
      state.isLoading = false;
      state.driverLocationListData = action.payload;
    },
    getfileDatasSuccess(state, action) {
      state.isLoading = false;
      state.fileDataRes = action.payload;
    },
    gethandoverListSuccess(state, action) {
      state.isLoading = false;
      state.handoverListDatas = action.payload;
    },
    gethandoverUserListSuccess(state, action) {
      state.isLoading = false;
      state.handoverUserListDatas = action.payload;
    },
    gethandoverDetailsSuccess(state, action) {
      state.isLoading = false;
      state.handoverDetailDatas = action.payload;
    },
    gethandoverAssignSuccess(state, action) {
      state.isLoading = false;
      state.handoverAssignRes = action.payload;
    },
    gethandoverUpdateSuccess(state, action) {
      state.isLoading = false;
      state.handoverUpdateRes = action.payload;
    },
    getAddCustomizeFareSuccess(state, action) {
      state.isLoading = false;
      state.addCustomizeFareRes = action.payload;
    },
    getQueueLocationListSuccess(state, action) {
      state.isLoading = false;
      state.queueLocationListData = action.payload;
    },
    getManageQueueLocationListSuccess(state, action) {
      state.isLoading = false;
      state.manageQueueLocationListData = action.payload;
    },
    getLocationGroupListSucess(state, action) {
      state.isLoading = false;
      state.locationGroupListData = action.payload;
    },
    getCompanyLocationGroupListSucess(state, action) {
      state.isLoading = false;
      state.companyLocationGroupListData = action.payload;
    },
    getLocationListStatusSuccess(state, action) {
      state.isLoading = false;
      state.locationDetailStatusRes = action.payload;
    },
    getManageCompanySuccess(state, action) {
      state.isLoading = false;
      state.manageCompanyData = action.payload;
    },
    getManageCompanyCsvSuccess(state, action) {
      state.isLoading = false;
      state.manageCompanyCsvData = action.payload;
    },
    getAddManageCarSuccess(state, action) {
      state.isLoading = false;
      state.addCarRecords = action.payload;
    },
    getEditManageCarSuccess(state, action) {
      state.isLoading = false;
      state.editCarRecords = action.payload;
    },

    // Santhosh - Start
    getCustomManufacturerSuccess(state, action) {
      state.isLoading = false;
      state.customManufacturerListData = action.payload;
    },

    getCustomManufacturerAddSuccess(state, action) {
      state.isLoading = false;
      state.customManufacturerAddRes = action.payload;
    },

    getCustomManufacturerEditSuccess(state, action) {
      state.isLoading = false;
      state.customManufacturerEditRes = action.payload;
    },

    getCustomManufacturerEditListSuccess(state, action) {
      state.isLoading = false;
      state.customManufacturerEditListRes = action.payload;
    },
    getCustomManufacturerUpdateStatus(state, action) {
      state.isLoading = false;
      state.customManufacturerStatusRes = action.payload;
    },
    getChangeCarMakeStatus(state, action) {
      state.isLoading = false;
      state.changeCarMakeStatusRes = action.payload;
    },

    getCarMakeListSuccess(state, action) {
      state.isLoading = false;
      state.CarMakeListData = action.payload;
    },

    getCarMakeAddSuccess(state, action) {
      state.isLoading = false;
      state.CarMakeAddRes = action.payload;
    },

    getCarMakerEditSuccess(state, action) {
      state.isLoading = false;
      state.CarMakeEditRes = action.payload;
    },

    getCarMakeEditListSuccess(state, action) {
      state.isLoading = false;
      state.CarMakeEditListRes = action.payload;
    },
    getCarMakeUpdateStatus(state, action) {
      state.isLoading = false;
      state.CarMakeStatusRes = action.payload;
    },
    getCarManufacturerListSuccess(state, action) {
      state.isLoading = false;
      state.ReportCarManufacturer = action.payload;
    },
    getCarMakefileDatasSuccess(state, action) {
      state.isLoading = false;
      state.carMakeImageFileDataRes = action.payload;
    },

    // Santhosh - End

    getEditManageCorporateSuccess(state, action) {
      state.isLoading = false;
      state.editCorporateRes = action.payload;
    },
    getAddManageCorporateSuccess(state, action) {
      state.isLoading = false;
      state.addCorporateRes = action.payload;
    },
    getManageCorpDashSuccess(state, action) {
      state.isLoading = false;
      state.manageCorpDashData = action.payload;
    },
    getPassengerListSuccess(state, action) {
      state.isLoading = false;
      state.passengerListDatas = action.payload;
    },
    getPassengerListsCVSuccess(state, action) {
      state.isLoading = false;
      state.passengerListCSVDatas = action.payload;
    },
    updatePassengerStatusSuccess(state, action) {
      state.isLoading = false;
      state.updatePassengerStatusRes = action.payload;
    },
    updateWalletAmountSuccess(state, action) {
      state.isLoading = false;
      state.updateWalletRes = action.payload;
    },
    getPassengerInfoSuccess(state, action) {
      state.isLoading = false;
      state.passengerInfoDatas = action.payload;
    },
    resetOtpCountSuccess(state, action) {
      state.isLoading = false;
      state.resetOtpRes = action.payload;
    },
    passengerEditSuccess(state, action) {
      state.isLoading = false;
      state.passengerEditRes = action.payload;
    },
    getAddZoneGroupSuccess(state, action) {
      state.isLoading = false;
      state.addZoneGroupRes = action.payload;
    },
    addZoneGroupFareMapSuccess(state, action) {
      state.isLoading = false;
      state.addZoneGroupFareMapRes = action.payload;
    },
    addZoneGroupFareMapNewSuccess(state, action) {
      state.isLoading = false;
      state.addZoneGroupFareMapNewRes = action.payload;
    },
    editZoneGroupFareMapSuccess(state, action) {
      state.isLoading = false;
      state.editZoneGroupFareMapRes = action.payload;
    },
    editZoneGroupFareMapNewSuccess(state, action) {
      state.isLoading = false;
      state.editZoneGroupFareMapNewRes = action.payload;
    },
    deleteZoneGroupFareMapSuccess(state, action) {
      state.isLoading = false;
      state.deleteZoneGroupFareMapRes = action.payload;
    },
    deleteZoneGroupFareMapNewSuccess(state, action) {
      state.isLoading = false;
      state.deleteZoneGroupFareMapNewRes = action.payload;
    },
    deleteFareMapSuccess(state, action) {
      state.isLoading = false;
      state.deleteFareMapRes = action.payload;
    },
    deleteFareMapNewSuccess(state, action) {
      state.isLoading = false;
      state.deleteFareMapNewRes = action.payload;
    },
    zoneGroupFareMapListSuccess(state, action) {
      state.isLoading = false;
      state.zoneGroupListFareMapRes = action.payload;
    },
    zoneGroupFareMapListNewSuccess(state, action) {
      state.isLoading = false;
      state.zoneGroupListFareMapNewRes = action.payload;
    },
    // zoneGroupFareMapNewListSuccess(state, action) {
    //   state.isLoading = false;
    //   state.zoneGroupListFareMapNewRes = action.payload;
    // },
    zoneCarModelListSuccess(state, action) {
      state.isLoading = false;
      state.zoneCarModelListData = action.payload;
    },
    zoneCarModelListNewSuccess(state, action) {
      state.isLoading = false;
      state.zoneCarModelListNewData = action.payload;
    },
    zoneGroupListSuccess(state, action) {
      state.isLoading = false;
      state.zoneGroupListRes = action.payload;
    },
    getListZoneSuccess(state, action) {
      state.isLoading = false;
      state.zoneListRes = action.payload;
    },
    getListForEditZoneSuccess(state, action) {
      state.isLoading = false;
      state.zoneListForEditRes = action.payload;
    },
    getZoneDetailSuccess(state, action) {
      state.isLoading = false;
      state.zoneDetailRes = action.payload;
    },
    editZoneGroupSuccess(state, action) {
      state.isLoading = false;
      state.editZoneRes = action.payload;
    },
    editManageZoneSuccess(state, action) {
      state.isLoading = false;
      state.editManageZoneRes = action.payload;
    },
    editManageZoneDetailSuccess(state, action) {
      state.isLoading = false;
      state.editManageZoneDetailData = action.payload;
    },
    getUpdateCorporateStatusSuccess(state, action) {
      state.isLoading = false;
      state.addCorporateStatusRes = action.payload;
    },
    getGetByCarListSuccess(state, action) {
      state.isLoading = false;
      state.getCarRecords = action.payload;
    },
    getUpdateCarStatusSuccess(state, action) {
      state.isLoading = false;
      state.carStatusUpdate = action.payload;
    },
    getTaxiInfoRecordsSuccess(state, action) {
      state.isLoading = false;
      state.taxiRecords = action.payload;
    },
    getTaxiCompleteListSuccess(state, action) {
      state.isLoading = false;
      state.taxiCompleteRecords = action.payload;
    },
    getTaxiSubscriptionListSuccess(state, action) {
      state.isLoading = false;
      state.taxiSubscriptionRecords = action.payload;
    },
    getCompanyAndCountryListSuccess(state, action) {
      state.isLoading = false;
      state.companyAndCountryDatas = action.payload;
    },
    getDriverAndTaxiListSuccess(state, action) {
      state.isLoading = false;
      state.driverAndTaxiDatas = action.payload;
    },
    assignDriverCarSuccess(state, action) {
      state.isLoading = false;
      state.assignDriverCarRes = action.payload;
    },
    getAssingedCarListSuccess(state, action) {
      state.isLoading = false;
      state.assignedCarDatas = action.payload;
    },
    getAssingedCarListCSVSuccess(state, action) {
      state.isLoading = false;
      state.assignedCarCSVDatas = action.payload;
    },
    getAssingedCarListCountsSuccess(state, action) {
      state.isLoading = false;
      state.assignedCarCountsDatas = action.payload;
    },
    getUpdateAssignedCarStatusSuccess(state, action) {
      state.isLoading = false;
      state.assignedStatusUpdate = action.payload;
    },
    getReportCompanyListSuccess(state, action) {
      state.isLoading = false;
      state.reportCompanyRec = action.payload;
    },
    getReportOtherListSuccess(state, action) {
      state.isLoading = false;
      state.reportOtherRec = action.payload;
    },
    getReportFilterListSuccess(state, action) {
      state.isLoading = false;
      state.reportFilterRecData = action.payload;
    },
    getReportFilterListCsvSuccess(state, action) {
      state.isLoading = false;
      state.reportFilterRecDataCsv = action.payload;
    },
    gethistoryReportListSuccess(state, action) {
      state.isLoading = false;
      state.historyRecordDatas = action.payload;
    },
    gethistoryReportListCSVSuccess(state, action) {
      state.isLoading = false;
      state.historyRecordCSVDatas = action.payload;
    },
    getReportFilterAccountSuccess(state, action) {
      state.isLoading = false;
      state.reportFilterRecAccount = action.payload;
    },
    getReportFilterAccountCsvSuccess(state, action) {
      state.isLoading = false;
      state.reportFilterRecAccountCsv = action.payload;
    },
    getSupervisorFilterListSuccess(state, action) {
      state.isLoading = false;
      state.supervisorFilterRecData = action.payload;
    },
    getSupervisorFilterListCsvSuccess(state, action) {
      state.isLoading = false;
      state.supervisorFilterRecDataCsv = action.payload;
    },
    getDriverUnassignedSuccess(state, action) {
      state.isLoading = false;
      state.unassigneRes = action.payload;
    },

    // jackson start
    getManageListSucess(state, action) {
      state.isLoading = false;
      state.manageCarListData = action.payload;
    },
    getManageNewDriverListSucess(state, action) {
      state.isLoading = false;
      state.manageNewDriverListData = action.payload;
    },
    getApprovedDriverSucess(state, action) {
      state.isLoading = false;
      state.approvedDriverData = action.payload;
    },
    getDisApprovedDriverSucess(state, action) {
      state.isLoading = false;
      state.disApprovedDriverData = action.payload;
    },
    getExportDriverStatusSucess(state, action) {
      state.isLoading = false;
      state.exportDriverStatusData = action.payload;
    },
    getNewManageDriverInfo(state, action) {
      state.isLoading = false;
      state.newManageDriverInfoData = action.payload;
    },
    // jackson end

     //models 

     getModelListSuccess(state, action) {
      state.isLoading = false;
      state.modelListData = action.payload;
    },

    getModelAddSuccess(state, action) {
      state.isLoading = false;
      state.modelAddRes = action.payload;
    },

    getModelEditListSuccess(state, action) {
      state.isLoading = false;
      state.modelEditListData = action.payload;
    },

    getModelEditSuccess(state, action) {
      state.isLoading = false;
      state.modelEditRes = action.payload;
    },

    getCarModelfileDatasSuccess(state, action) {
      state.isLoading = false;
      state.carModelImageFileDataRes = action.payload;
    },
  
    
    getCarDriverDatasSuccess(state, action) {
      state.isLoading = false;
      state.carDriverImageFileDataRes = action.payload;
    },


    
    getCarDriverImageUploadSuccess(state, action) {
      state.isLoading = false;
      state.carDriverImageFileRes = action.payload;
    },

    //Pagination
    getPaginationSuccess(state, action) {
      state.isLoading = false;
      state.paginationTransaction = action.payload;
    },
    getTransactionEditSuccess(state, action) {
      state.isLoading = false;
      state.transactionEditRec = action.payload;
    },
    getSupervisorLogsSuccess(state, action) {
      state.isLoading = false;
      state.supervisorLogsRec = action.payload;
    },
    getReportCountSuccess(state, action) {
      state.isLoading = false;
      state.reportCountRes = action.payload;
    },
    getAddCompanySuccess(state, action) {
      state.isLoading = false;
      state.addCompanyRes = action.payload;
    },
    getEditCompanySuccess(state, action) {
      state.isLoading = false;
      state.editCompanyRes = action.payload;
    },
    getEditCompanyDetailSuccess(state, action) {
      state.isLoading = false;
      state.companyDetailRes = action.payload;
    },
    getCompanyStatusUpdateSuccess(state, action) {
      state.isLoading = false;
      state.companyStatusUpdateRes = action.payload;
    },
    getCompanyDashboardSuccess(state, action) {
      state.isLoading = false;
      state.companyDashboardRes = action.payload;
    },
    getUserLoginSuccess(state, action) {
      state.isLoading = false;
      state.useLoginRes = action.payload;
    },
    getUserAddSuccess(state, action) {
      state.isLoading = false;
      state.userAddRes = action.payload;
    },
    getCompanyUserListSuccess(state, action) {
      state.isLoading = false;
      state.companyUserListRes = action.payload;
    },
    getCompanyUserListCsvSuccess(state, action) {
      state.isLoading = false;
      state.companyUserListCsvRes = action.payload;
    },
    getEditCompanyUserSuccess(state, action) {
      state.isLoading = false;
      state.editCompanyUserRes = action.payload;
    },
    getUpdateCompanyUserSuccess(state, action) {
      state.isLoading = false;
      state.CompanyUserUpdateRes = action.payload;
    },
    getCompanyPermissionSuccess(state, action) {
      state.isLoading = false;
      state.companyPermissionRes = action.payload;
    },
    getUppdateCompanyPermissionSuccess(state, action) {
      state.isLoading = false;
      state.updateCompanyPermissionRes = action.payload;
    },
    getCompanyUserDashboardSuccess(state, action) {
      state.isLoading = false;
      state.companyResUserDashRes = action.payload;
    },
    getCompanyUserStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateCompanyUserRes = action.payload;
    },
    getactivityLogsSuccess(state, action) {
      state.isLoading = false;
      state.activityLogsRes = action.payload;
    },
    getEditedJsonSuccess(state, action) {
      state.isLoading = false;
      state.editedJsonRes = action.payload;
    },
    getDriverReviewList(state, action) {
      state.isLoading = false;
      state.DriverReviewList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/user/account/notifications-settings'
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// reset password start
export function ResetLoginForm(email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      email: email
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/forgotPassword`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getResetLoginFormSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function changeUserPassword(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/changePassword`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.changeUserPasswordSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserProfile(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/getUserProfile`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getUserProfileSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateUserProfile(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();
    formData.append('userId', value.userId);
    formData.append('companyId', value.companyId);
    formData.append('firstName', value.firstName);
    formData.append('lastName', value.lastName);
    formData.append('email', value.email);
    // formData.append('countryCode', value.countryCode);
    // formData.append('phone', value.phone);
    formData.append('address', value.address);
    formData.append('companyName', value.companyName);
    formData.append('document', value.companyProfile);

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/updateProfile`,
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.updateUserProfileSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// reset password end

// driver status

export function getBookingDetail(values, datas) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var reqBody = {
      fromDate: datas.fromDate,
      toDate: datas.toDate,
      type: values
    };
    try {
      const response = await axios.post(
        'http://54.196.74.135:5050/api/getMobileAppTripCopy',
        reqBody
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getPassengerDetailSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTopDriverInfo(values, bookingType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      fromDate: values.fromDate + ' 00:00:00',
      toDate: values.toDate + ' 23:59:00',
      bookingType: bookingType
    };
    try {
      // const response = await axios.post(
      //   'http://34.197.71.192:3001/passenger/driverAcceptedCount?dt=a&v=1&lang=en&dID=d1d727c10825c265',
      //   obj
      // );
      const response = await axios.post(
        `${webAPI_URL}/passenger/driverAcceptedCount`,
        obj
      );
      // const response = await axios.post(
      //   'https://ridenode.limor.us/passenger/driverAcceptedCount',
      //   obj
      // );

      if (response.data !== undefined) {
        dispatch(slice.actions.getTopDriverInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTaxiDispatch(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newbooking/allDrivers`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getTaxiDispatchSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllDriverStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newbooking/allDriversStatus`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAllDriverStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupervisorInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/manageSupervisorList`,
        obj
        // `${webAPI_URL}/passenger/supervisorList`, obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupervisorInfoCSV(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/manageSupervisorList`,
        obj
        // `${webAPI_URL}/passenger/supervisorList`, obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorInfoCSVSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addSupervisor(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/addSupervisor`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.addSupervisorSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editSupervisor(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/editSupervisor`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.editSupervisorSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function unAssignSuperVisor(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/unassignSupervisor`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.unAssignSupervisorSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unpdateSuperVisorStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/changeSupervisorStatus`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.unpdateSuperVisorStatusSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupervisorDetails(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/getSupervisorDetails`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorDetailsSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSearchTripId(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/searchTripId`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSearchTripIdSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupervisorDetailsInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/supervisorListById`,
        values
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorDetailsInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getManageSupervisorInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageSupervisor/info`,
        values
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getManageSupervisorInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPushNotificationInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/pushNotification`,
        values
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPushNotificationInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupervisorMonitorLogInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/supervisorMonitorLog`,
        values
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorMonitorLogSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupervisorMonitorInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      id: values
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/supervisorMonitorList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorMonitorSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomZoneInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/georeport/customZones`);

      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomZoneAllInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/passengerTripCountss`,
        values
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneAllSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passengerData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/pushNotification/geoPassengerList`
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerData1Sucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPassengerPushNotificationInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      type: parseInt(values)
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/pushNotification/passengerPushNotification`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerPushAllSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPassengerDetailsInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      passengerId: parseInt(values)
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/pushNotification/getByPassengerId`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerDetailsSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPassengerDataInfo(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      passengerId: parseInt(values)
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/pushNotification/getPassengerData`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerDataSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPushNotificationInfo1(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/pushNotification/pushNotification`,
        values
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPushNotificationInfo1Sucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKioskListInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/georeport/kioskList`);

      if (response.data !== undefined) {
        dispatch(slice.actions.getKioskListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKioskGetByIdInfo(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/kioskGetById`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getKioskListByIdSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKioskUpdateInfo(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/kioskNotificationTime`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getKioskDataUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// New Booking

export function getCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${webAPI_URL}/newBooking/companyListRestrict`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyListAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${webAPI_URL}/newBooking/companyListAll`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyListAllSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`${webAPI_URL}/newBooking/carList`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAllManufacturerList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/allManufacturerList`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAllManufacturerListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyManufacturerList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/companyManufacturerList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getCompanyManufacturerListSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyMakeList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/companyMakeList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyMakeListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarListFilter() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`${webAPI_URL}/newBooking/carList`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarListFilterSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarMakeList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getCarMakeList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCorporateList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/corporateList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCorporateListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyCorporateList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/companyCorporateList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyCorporateListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUpcomingBookingList(values, type, pageNumber, pageLimit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      bookingStatus: values.bookingStatus,
      bookingType: values.bookingType,
      bookingFrom: values.bookingFrom,
      bookingFromNew: values.bookingFromNew,
      createdFrom: values.createdFrom,
      company: values.company,
      carType: values.carType,
      corporate: values.corporate,
      fromDate:
        values.fromDate !== null
          ? moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss')
          : ' ',
      toDate:
        values.toDate !== null
          ? moment(new Date(values.toDate)).format('YYYY-MM-DD HH:mm:ss')
          : ' ',
      tripId: values.tripId !== 0 ? parseInt(values.tripId) : ' ',
      search2: values.search2,
      type: type,
      pageNumber: pageNumber,
      pageLimit: pageLimit,
      referenceNumber: values.referenceNumber,
      staff_number: values.staff_number,
      assignToDriver: values.assignToDriver,
      // operational_supervisor: values.operational_supervisor,
      dispatcher_booking_created_by: values.dispatcher_booking_created_by
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/upcomingBooking`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpcomingBookingSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBookingPrice(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value.id,
      value: parseInt(value.value),
      filter: value.filter
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/bookingPriceUpdate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getBookingPriceSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCancelBooking(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value.id,
      reason: value.reason,
      travelStatus: 8
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/cancelBookingUpdate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCancelBookingSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByPassenger(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getByPassengerEditDetailsNew`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByPassengerSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByPassengerTransaction(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      _id: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getTripDetailById`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByPassengerTransactionSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByCar(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getByCarDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCarSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByCarMake(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getByCarMakeDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCarMakeSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByPassengerCard(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getByPassengerDetails`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByPassengerCardSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editUpdate(
  value,
  pickupTime,
  pickupLatlng,
  dropLatlng,
  wayPath,
  waypoints,
  zone_fare_applied,
  reAssignDriver
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const pickupDate = moment(new Date(pickupTime)).format(
      'YYYY-MM-DDTHH:mm:ss.000Z'
    );

    // var dropArr = [];
    var dropArr =
      typeof value.drop_location === 'string'
        ? [value.drop_location]
        : [value.drop_location[0]];
    var dropLatArr = [];
    var dropLngArr = [];

    // dropArr.push(value.drop_location);
    dropLatArr.push(dropLatlng.lat);
    dropLngArr.push(dropLatlng.lng);

    wayPath.map((item) => {
      dropArr.push(item);
    });

    waypoints.map((item) => {
      dropLatArr.push(item.location.lat);
      dropLngArr.push(item.location.lng);
    });

    const obj = {
      id: value._id,
      pickup_time: pickupDate,
      motor_model_info: value.motor_model_info,
      car_make_info: value.car_make_info,
      custom_price: {
        customer_price:
          value.package_type === '' && value.customer_price
            ? parseFloat(value.customer_price)
            : 0,
        customer_hourly_rate:
          value.package_type === '' && value.customer_hourly_rate
            ? parseFloat(value.customer_hourly_rate)
            : 0,
        driver_price: parseFloat(value.driver_price),
        driver_hourly_rate: parseFloat(value.driver_hourly_rate),
        trip_duration: parseFloat(value.trip_duration)
      },
      note_to_driver: value.note_to_driver,
      notes: value.notes,
      flight_number: value.flight_number,
      reference_number: value.reference_number,
      promocode: value.promocode,
      assignToDriver: value.assignToDriver,
      current_location: value.current_location,
      drop_location: dropArr,
      // drop_location: value.drop_location,
      pickup_notes: value.pickup_notes,
      drop_notes: value.drop_notes,
      payment_method: value.passenger_payment_option,
      passenger_payment_option: value.passenger_payment_option,
      final_payment_option: value.final_payment_option,
      pickup_latitude: pickupLatlng.lat,
      pickup_longitude: pickupLatlng.lng,
      drop_latitude: dropLatArr,
      drop_longitude: dropLngArr,
      guest_email: value.guest_email,
      guest_name: value.guest_name,
      guest_phone: value.guest_phone,
      guest_country_code: value.guest_country_code,
      staff_number: value.staff_number,
      operational_supervisor: value.operational_supervisor,
      rsl_share:
        value.package_type === '' && value.rsl_share
          ? parseFloat(value.rsl_share)
          : 0,
      room_number: value.room_number,
      corporate_share:
        value.package_type === '' && value.corporate_share
          ? parseFloat(value.corporate_share)
          : 0,
      fare: parseFloat(value.fare),
      parking_charge:
        value.package_type === '' && value.parking_charge
          ? value.parking_charge
          : 0,
      extra_charge:
        value.package_type === '' && value.extra_charge !== ''
          ? parseInt(value.extra_charge)
          : undefined,
      remarks: value.remarks !== '' ? value.remarks : undefined,
      customer_rate:
        value.package_type === '' && value.customer_rate
          ? value.customer_rate
          : 0,
      trip_type:
        value.zone_fare_applied == 1 && value.trip_type !== ''
          ? value.trip_type
          : undefined,
      package_id: value.package_id ? parseInt(value.package_id) : 0,
      package_type: value.package_type ? parseInt(value.package_type) : 0,
      is_package_booking: value.package_id ? 1 : 0,
      double_the_fare:
        value.package_type === '' &&
        value.zone_fare_applied == 1 &&
        value.double_the_fare !== '' &&
        value.trip_type === '2'
          ? value.double_the_fare
          : undefined,
      driver_share:
        value.package_type === '' && value.driver_share
          ? parseFloat(value.driver_share)
          : 0,
      zone_fare_applied:
        value.package_type === '' && value.zone_fare_applied == 1
          ? value.zone_fare_applied
          : undefined,
      pickup_zone_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_id : undefined,
      pickup_zone_group_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_group_id : undefined,
      drop_zone_id:
        value.zone_fare_applied == 1 ? value.drop_zone_id : undefined,
      drop_zone_group_id:
        value.zone_fare_applied == 1 ? value.drop_zone_group_id : undefined,
      package_fare: value.package_fare ? parseFloat(value.package_fare) : 0,
      driverId: reAssignDriver.driverId ? reAssignDriver.driverId : '',
      taxiId: reAssignDriver.taxiId ? reAssignDriver.taxiId : '',
      bookingType: reAssignDriver.bookingType ? reAssignDriver.bookingType : '',
      assignType: reAssignDriver.assignType ? reAssignDriver.assignType : ''
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/updateEditpageNew`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByEditUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getByVerify(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/newbooking/verifyBooking`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByVerifySucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// B2b and B2c Upcoming
export function getB2BandB2CSummary(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/summary`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getB2bandB2CSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// B2b and B2c PastBooking

export function getPastBookingSummary(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // const obj = {
    //   fromDate: value.fromDate ? moment(new Date(value.fromDate)).format('YYYY-MM-DD HH:mm:ss') : '',
    //   toDate: value.toDate ? moment(new Date(value.toDate)).format('YYYY-MM-DD HH:mm:ss') : ''
    // }
    const obj = {
      bookingStatus: values.bookingStatus,
      bookingType: values.bookingType,
      bookingFrom: values.bookingFrom,
      bookingFromNew: values.bookingFromNew,
      createdFrom: values.createdFrom,
      company: values.company,
      carType: values.carType,
      corporate: values.corporate,
      fromDate:
        values.fromDate !== null
          ? moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss')
          : '',
      toDate:
        values.toDate !== null
          ? moment(new Date(values.toDate)).format('YYYY-MM-DD HH:mm:ss')
          : '',
      tripId: values.tripId !== 0 ? parseInt(values.tripId) : '',
      search2: values.search2,
      type: 'pastbooking',
      // pageNumber: pageNumber,
      // pageLimit: pageLimit,
      referenceNumber: values.referenceNumber,
      staff_number: values.staff_number,
      assignToDriver: values.assignToDriver,
      // operational_supervisor: values.operational_supervisor,
      dispatcher_booking_created_by: values.dispatcher_booking_created_by
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/pastBookingSummary`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPastBookingSummarySucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Jack
export function corporateUpdate(
  value,
  pickupTime,
  pickupLatlng,
  dropLatlng,
  wayPath,
  waypoints
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const pickupDate = moment(new Date(pickupTime)).format(
      'YYYY-MM-DDTHH:mm:ss.000Z'
    );

    var dropArr =
      typeof value.drop_location === 'string'
        ? [value.drop_location]
        : [value.drop_location[0]];
    var dropLatArr = [];
    var dropLngArr = [];

    // dropArr.push(value.drop_location);
    dropLatArr.push(dropLatlng.lat);
    dropLngArr.push(dropLatlng.lng);

    wayPath.map((item) => {
      dropArr.push(item);
    });

    waypoints.map((item) => {
      dropLatArr.push(item.location.lat);
      dropLngArr.push(item.location.lng);
    });

    console.log('dropArr2:', dropArr, wayPath);

    const obj = {
      id: value._id,
      pickup_time: pickupDate,
      motor_model_info: value.motor_model_info,
      car_make_info: value.car_make_info,
      custom_price: {
        customer_price: parseFloat(value.customer_price),
        customer_hourly_rate: parseFloat(value.customer_hourly_rate),
        driver_price: parseFloat(value.driver_price),
        driver_hourly_rate: parseFloat(value.driver_hourly_rate),
        trip_duration: parseFloat(value.trip_duration)
      },
      note_to_driver: value.note_to_driver,
      notes: value.notes,
      flight_number: value.flight_number,
      reference_number: value.reference_number,
      promocode: value.promocode,
      assignToDriver: value.assignToDriver,
      current_location: value.current_location,
      drop_location: dropArr,
      pickup_latitude: pickupLatlng.lat,
      pickup_longitude: pickupLatlng.lng,
      drop_latitude: dropLatArr,
      drop_longitude: dropLngArr,
      // drop_location: value.drop_location,
      pickup_notes: value.pickup_notes,
      drop_notes: value.drop_notes,
      payment_method: value.passenger_payment_option,
      // payment_method: '1',
      passenger_payment_option: value.passenger_payment_option,
      // passenger_payment_option: '1',
      final_payment_option: value.final_payment_option,
      guest_email: value.guest_email,
      guest_name: value.guest_name,
      guest_phone: value.guest_phone,
      guest_country_code: value.guest_country_code,
      staff_number: value.staff_number,
      operational_supervisor: value.operational_supervisor,
      dispatcher_booking_created_by: value.dispatcher_booking_created_by,
      parking_charge: value.parking_charge,
      extra_charge:
        value.extra_charge !== '' ? parseInt(value.extra_charge) : undefined,
      remarks: value.remarks !== '' ? value.remarks : undefined,
      rsl_share: parseFloat(value.rsl_share),
      room_number: value.room_number,
      corporate_share: parseFloat(value.corporate_share),
      fare: parseFloat(value.fare),
      driver_share: parseFloat(value.driver_share),
      zone_fare_applied:
        value.zone_fare_applied == 1 ? value.zone_fare_applied : undefined,
      pickup_zone_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_id : undefined,
      pickup_zone_group_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_group_id : undefined,
      drop_zone_id:
        value.zone_fare_applied == 1 ? value.drop_zone_id : undefined,
      drop_zone_group_id:
        value.zone_fare_applied == 1 ? value.drop_zone_group_id : undefined,
      trip_type:
        value.zone_fare_applied == 1 && value.trip_type !== ''
          ? value.trip_type
          : undefined,
      double_the_fare:
        value.zone_fare_applied == 1 &&
        value.double_the_fare !== '' &&
        value.trip_type === '2'
          ? value.double_the_fare
          : undefined

      // drop_latitude: dropLatlng.lat,
      // drop_longitude: dropLatlng.lng
    };

    try {
      console.log('testing');
      const response = await axios.post(
        `${webAPI_URL}/newbooking/editCorporateBooking`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCorporateUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function nearestDriver(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/nearestDriverList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByNearestDriverSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function actionDriveData(driverValue, tripId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      driverDetails: driverValue,
      tripId: tripId
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/driverAction`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByActionDriverDataSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function assignDriver(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/sendDriverReqest`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByAssignDriverDataSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPassenger(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/passengerAdd`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByAddPassengerSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editPassenger(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/passengerEdit`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByEditPassengerSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Exit record

export function getExitRecord() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        'http://golocation-e430b3a3bd6ca08e.elb.me-south-1.amazonaws.com:8809/api/v1/listOfExitRecord'
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getExitRecordInfoSucess(response.data.responce));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverDocument() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/driverDocuments`
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getDriverDocumentSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverFilterDocument(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      fromDate: value.fromDate + ' 00:00:00',
      toDate: value.toDate + ' 23:59:59'
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/filterDriverDocuments`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getDriverFilterDocumentSucess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverFilterDocumentStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      // const response = await axios.post(
      //   'https://passnode.limor.us/passenger/documentStatusUpdate',
      //   value
      // );
      const response = await axios.post(
        'https://ridenode.limor.us/passenger/documentStatusUpdate',
        value
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getDriverFilterDocumentStatusSucess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passengerRecords(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // var  phone = value;

    // if (value && value.length > 0 && value.charAt(0) === '0'){
    //   phone = value.slice(1);
    // }

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/passengerList`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getByPassengerDataSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function corporateRecords(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // const obj = {
    //   value: value,
    //   company_id: 0
    // };
    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/corporateListAll`,
        obj
      );
      if (response.data.responseDate !== undefined) {
        dispatch(
          slice.actions.getByCorporateDataSucess(response.data.responseDate)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function packageRecords(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/packageListAll`,
        value
      );
      if (response.data.responseDate !== undefined) {
        dispatch(
          slice.actions.getByPackageDataSucess(response.data.responseDate)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function MotorCarList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/motorModelList`,
        value
      );
      if (response.data.responseDate !== undefined) {
        dispatch(
          slice.actions.getByMotorCarListSucess(response.data.responseDate)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CarModelList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/carModelList`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarModelListSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SaveBooking(
  value,
  fareValue,
  packageFareValue,
  dates,
  directions,
  wayPath,
  waypoints
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // function subtractTwoMinutes(dates) {
    //   const [datePart, timePart] = dates.split(' ');
    //   const [hours, minutes, seconds] = timePart.split(':');

    //   // Convert hours, minutes, and seconds to numbers
    //   let hoursNum = parseInt(hours, 10);
    //   let minutesNum = parseInt(minutes, 10);
    //   let secondsNum = parseInt(seconds, 10);

    //   // Subtract 2 minutes
    //   minutesNum -= 2;

    //   // Adjust hours and minutes if necessary
    //   if (minutesNum < 0) {
    //     minutesNum += 60;
    //     hoursNum -= 1;
    //   }

    //   // Ensure hours and minutes are two digits
    //   const formattedHours = hoursNum.toString().padStart(2, '0');
    //   const formattedMinutes = minutesNum.toString().padStart(2, '0');

    //   // Return the result
    //   return `${datePart} ${formattedHours}:${formattedMinutes}:${seconds}`;
    // }

    // console.log('Dates:', dates);
    // const result = subtractTwoMinutes(dates);
    // console.log('result:', result);

    var dropArr = [];
    // var dropArr = typeof value.drop_location ==="string"?[value.drop_location]:[value.drop_location[0]];
    var dropLatArr = [];
    var dropLngArr = [];

    dropArr.push(value.dropLocation);
    dropLatArr.push(value.drop_latitude);
    dropLngArr.push(value.drop_longitude);

    wayPath.map((item) => {
      dropArr.push(item);
    });

    waypoints.map((item) => {
      dropLatArr.push(item.location.lat);
      dropLngArr.push(item.location.lng);
    });

    // const obj = {
    //   approx_distance: value.approx_distance,
    //   approx_duration: value.approx_duration,
    //   approx_trip_fare: fareValue >= 0 ? fareValue : 0,
    //   // approx_trip_fare: value.customer_price >= 0 ? value.approx_trip_fare : '',
    //   cityname: '',
    //   distance_away: 0,
    //   drop_latitude: dropLatArr,
    //   drop_longitude: dropLngArr,
    //   // drop_latitude: value.drop_latitude,
    //   // drop_longitude: value.drop_longitude,
    //   drop_makani: '',
    //   drop_notes: '',
    //   // dropplace: value.dropLocation,
    //   dropplace: dropArr,
    //   fav_driver_booking_type: 0,
    //   friend_id1: value.passenger_info.passengerSelectedOption
    //     ? value.passenger_info.passengerSelectedOption
    //     : value.corporate_info.corporate_id
    //     ? value.corporate_info.corporate_id
    //     : '',
    //   friend_id2: 0,
    //   friend_id3: 0,
    //   friend_id4: 0,
    //   friend_percentage1: 100,
    //   friend_percentage2: 0,
    //   friend_percentage3: 0,
    //   friend_percentage4: 0,
    //   guest_name: value.guest_name,
    //   guest_phone: value.guest_phone,
    //   guest_email: value.guest_email,
    //   guest_country_code: value.guest_country_code.includes('+')
    //     ? value.guest_country_code
    //     : `+${value.guest_country_code}`,
    //   is_guest_booking: value.is_guest_booking,
    //   latitude: value.latitude,
    //   longitude: value.longitude,
    //   motor_model: value.motor_model_info.motor_model,
    //   car_make_id: value.motor_model_info.car_make_id,
    //   notes: '',
    //   now_after: value.bookingtype,
    //   passenger_id: value.passenger_info.passengerSelectedOption
    //     ? value.passenger_info.passengerSelectedOption
    //     : '',
    //   corporate_id: value.corporate_info.corporate_id
    //     ? value.corporate_info.corporate_id
    //     : '',
    //   passenger_payment_option: parseInt(value.payment_method),
    //   payment_mode: '1',
    //   pickupplace: value.pickupLocation,
    //   pickupLandmark: value.pickupLandmark,
    //   dropLandmark: value.dropLandmark,
    //   pickup_makani: '',
    //   pickup_notes: '',
    //   pickup_time: dates,
    //   promo_code: value.promocode,
    //   assignToDriver: value.assignToDriver,
    //   payment_by: value.payment_by,
    //   package_type:
    //     value.package_type !== '' ? parseInt(value.package_type) : '',
    //   package_id: value.package_id,
    //   request_type: '1',
    //   company_id: value.company_id,
    //   sub_logid: '',
    //   trip_duration: parseFloat(value.trip_duration),
    //   note_to_driver: value.note_to_driver,
    //   flight_number: value.flight_number,
    //   reference_number: value.reference_number,
    //   customer_price: value.fare ? parseFloat(value.fare) : 0,
    //   customer_hourly_rate: value.customer_hourly_rate
    //     ? parseFloat(value.customer_hourly_rate)
    //     : 0,
    //   driver_price: value.driver_price ? parseFloat(value.driver_price) : 0,
    //   driver_hourly_rate: value.driver_hourly_rate
    //     ? parseFloat(value.driver_hourly_rate)
    //     : 0,
    //   route_polyline: directions,
    //   operational_supervisor: value.operational_supervisor,
    //   is_custom_price: value.fare ? 1 : 0,
    //   staff_number: value.staff_number,
    //   dispatcher_booking_created_by: value.dispatcher_booking_created_by,
    //   rsl_share: parseFloat(value.rsl_share),
    //   room_number: value.room_number,
    //   corporate_share: parseFloat(value.corporate_share),
    //   driver_share: parseFloat(value.driver_share),
    //   zone_fare_applied: value.zone_fare_applied,
    //   pickup_zone_id:
    //     value.zone_fare_applied == 1 ? value.pickup_zone_id : undefined,
    //   pickup_zone_group_id:
    //     value.zone_fare_applied == 1 ? value.pickup_zone_group_id : undefined,
    //   drop_zone_id:
    //     value.zone_fare_applied == 1 ? value.drop_zone_id : undefined,
    //   drop_zone_group_id:
    //     value.zone_fare_applied == 1 ? value.drop_zone_group_id : undefined,
    //   extra_charge:
    //     value.extra_charge !== '' ? parseInt(value.extra_charge) : undefined,
    //   remarks: value.remarks !== '' ? value.remarks : undefined,
    //   trip_type:
    //     value.zone_fare_applied == 1 && value.trip_type !== ''
    //       ? value.trip_type
    //       : undefined,
    //   double_the_fare:
    //     value.zone_fare_applied == 1 &&
    //     value.double_the_fare !== '' &&
    //     value.trip_type === '2'
    //       ? value.double_the_fare
    //       : undefined
    // };

    const obj = {
      approx_distance: value.package_id
        ? packageFareValue?.package_max_km
        : value.approx_distance,
      approx_duration: value.package_id
        ? packageFareValue?.package_hrs_days
        : value.approx_duration,
      approx_trip_fare: value.package_id
        ? packageFareValue?.package_amount
        : fareValue >= 0
        ? fareValue
        : 0,
      // approx_trip_fare: value.customer_price >= 0 ? value.approx_trip_fare : '',
      cityname: '',
      distance_away: 0,
      drop_latitude: dropLatArr,
      drop_longitude: dropLngArr,
      // drop_latitude: value.drop_latitude,
      // drop_longitude: value.drop_longitude,
      drop_makani: '',
      drop_notes: '',
      // dropplace: value.dropLocation,
      dropplace: dropArr,
      fav_driver_booking_type: 0,
      friend_id1: value.passenger_info.passengerSelectedOption
        ? value.passenger_info.passengerSelectedOption
        : value.corporate_info.corporate_id
        ? value.corporate_info.corporate_id
        : '',
      friend_id2: 0,
      friend_id3: 0,
      friend_id4: 0,
      friend_percentage1: 100,
      friend_percentage2: 0,
      friend_percentage3: 0,
      friend_percentage4: 0,
      guest_name: value.guest_name,
      guest_phone: value.guest_phone,
      guest_email: value.guest_email,
      guest_country_code: value.guest_country_code.includes('+')
        ? value.guest_country_code
        : `+${value.guest_country_code}`,
      is_guest_booking: value.is_guest_booking,
      latitude: value.latitude,
      longitude: value.longitude,
      motor_model: value.motor_model_info.motor_model,
      car_make_id: value.motor_model_info.car_make_id,
      notes: value.note_to_admin,
      now_after: value.bookingtype,
      passenger_id: value.passenger_info.passengerSelectedOption
        ? value.passenger_info.passengerSelectedOption
        : '',
      corporate_id: value.corporate_info.corporate_id
        ? value.corporate_info.corporate_id
        : '',
      passenger_payment_option: parseInt(value.payment_method),
      payment_mode: '1',
      pickupplace: value.pickupLocation,
      pickupLandmark: value.pickupLandmark,
      dropLandmark: value.dropLandmark,
      pickup_makani: '',
      pickup_notes: '',
      pickup_time: dates,
      promo_code: value.promocode,
      assignToDriver: value.assignToDriver,
      payment_by: value.payment_by,
      package_type:
        value.package_type !== '' ? parseInt(value.package_type) : '',
      package_id: value.package_id,
      request_type: '1',
      company_id: value.company_id,
      sub_logid: '',
      trip_duration:
        value.package_type !== '' && value.trip_duration
          ? parseFloat(value.trip_duration)
          : 0,
      note_to_driver: value.note_to_driver,
      flight_number: value.flight_number,
      reference_number: value.reference_number,
      customer_price:
        value.package_type === '' && value.fare ? parseFloat(value.fare) : 0,
      customer_hourly_rate:
        value.package_type === '' && value.customer_hourly_rate
          ? parseFloat(value.customer_hourly_rate)
          : 0,
      driver_price: value.driver_price ? parseFloat(value.driver_price) : 0,
      driver_hourly_rate: value.driver_hourly_rate
        ? parseFloat(value.driver_hourly_rate)
        : 0,
      route_polyline: directions,
      operational_supervisor: value.operational_supervisor,
      is_custom_price: value.package_type === '' && value.fare ? 1 : 0,
      staff_number: value.staff_number,
      dispatcher_booking_created_by: value.dispatcher_booking_created_by,
      rsl_share:
        value.package_type === '' && value.rsl_share
          ? parseFloat(value.rsl_share)
          : 0,
      room_number: value.room_number,
      corporate_share:
        value.package_type === '' && value.corporate_share
          ? parseFloat(value.corporate_share)
          : 0,
      driver_share:
        value.package_type === '' && value.driver_share
          ? parseFloat(value.driver_share)
          : 0,
      zone_fare_applied:
        value.package_type === '' && value.zone_fare_applied
          ? value.zone_fare_applied
          : 0,
      pickup_zone_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_id : undefined,
      pickup_zone_group_id:
        value.zone_fare_applied == 1 ? value.pickup_zone_group_id : undefined,
      drop_zone_id:
        value.zone_fare_applied == 1 ? value.drop_zone_id : undefined,
      drop_zone_group_id:
        value.zone_fare_applied == 1 ? value.drop_zone_group_id : undefined,
      extra_charge:
        value.package_type === '' && value.extra_charge !== ''
          ? parseInt(value.extra_charge)
          : undefined,
      remarks: value.remarks !== '' ? value.remarks : undefined,
      trip_type:
        value.package_type === '' &&
        value.zone_fare_applied == 1 &&
        value.trip_type !== ''
          ? value.trip_type
          : undefined,
      double_the_fare:
        value.package_type === '' &&
        value.zone_fare_applied == 1 &&
        value.double_the_fare !== '' &&
        value.trip_type === '2'
          ? value.double_the_fare
          : undefined
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/saveBooking`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getBySaveBookingSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function FareDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // const Obj = {
    //   company_id: 7
    // };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/motorDetailsNew`,
        value
      );

      // const response = await axios.post(
      //   `${webAPI_URL}/newBooking/motorDetails`,
      //   Obj
      // );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByFareDetailsSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCarMakeandCategory(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/allCarMakeAndCategory`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAllCarMakesSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarMakeFare(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getCarMakeFare`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeFareSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function FareDetailsCarMake(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/carMakeList`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByFareDetailsCarMakeSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CorporateDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // const obj = {
    //   corporateId: 0
    // };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/getCorporatePackageList`,
        value
      );
      // const response = await axios.post(
      //   `${webAPI_URL}/newBooking/motorDetails`,
      //   Obj
      // );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByCorporateDetailsSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function OverViewData(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const Obj = {
      tripId: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/overView`,
        Obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getByOverViewSucess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function EndTripApi(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webLive_URL}/driverapi119/index/?type=tripEndWeb&gt_lst_time=1664783608`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getByEndTripSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CurrentDriverLocation(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/currentDriverLocation`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getByCurrentDriverLocationSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LocationGroup(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      fromDate: value.fromDate + ' 00:00:00',
      toDate: value.toDate + ' 23:59:59'
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/locationGroupList`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getLocationGroupSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LocationTransaction(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      cid: value.cid,
      trip_id: value.trip_id
      // trip_id: 2117850
    };

    try {
      const response = await axios.post(
        `https://web.limor.us/supervisorapp/index/?type=trip_map_datas`,
        obj
      );

      if (response.data.detail !== undefined) {
        dispatch(
          slice.actions.getLocationTransactionSucess(response.data.detail)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddRecord(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/addLocationGroup`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAddRecordInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEditRecord(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/editLocationGroup`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditRecordInfoSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function locationStatusUpdate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/updateStatusLocationGroup`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationStatusUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function chatList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        'https://ridenode.limor.us/passenger/getDriverDocumentById',
        value
      );

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getChatListSucess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function chatListUpdate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();
    formData.append('id', value.id);
    formData.append('label', value.label);
    formData.append('document', value.document);
    formData.append('user_type', value.user_type);
    formData.append('driver_id', value.driver_id);
    try {
      const response = await axios.post(
        'https://ridenode.limor.us/passenger/documentStatusUpdateMob',
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getChatListUpdateSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function DriverSendSms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/corporateTripReminder`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSendDriverSmsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function DriverSendMail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/corporateEmailSend`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSendDriverMailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageDriverList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/manageDriverList`
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getManageDriverListSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addManageDriver(values, imagesName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      shift_status: 'OUT',
      status: 'F',
      update_date:
        moment(new Date()).format('YYYY-MM-DD') +
        'T' +
        moment(new Date()).format('HH:mm:ss') +
        '.000Z'
    };

    const driverObj = {
      ...values.driver_info,
      ...obj
    };

    //     driver_info: {
    //   shift_status: 'OUT',
    //   status: 'F',
    //   update_date: 2023-07-03T10:19:35.486Z,
    //   driver_insurance_expire_date: 2023-07-05T00:00:00.000Z,
    //   driver_license_expire_date: 2023-07-14T00:00:00.000Z,
    //   driver_pco_license_expire_date: 2023-07-21T00:00:00.000Z
    // },
    const formData = new FormData();
    formData.append('driver_no', values.driver_no);
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('country_code', values.country_code);
    formData.append('gender', values.gender);
    formData.append('dob', values.dob);
    formData.append('company_id', JSON.stringify(values.company_id));
    formData.append('driver_license_id', values.driver_license_id);
    // formData.append(
    //   'driver_license_expire_date',
    //   values.driver_license_expire_date
    // );
    // formData.append('rta_card_id', values.rta_card_id);
    // formData.append('rta_card_expiry_date', values.rta_card_expiry_date);
    // formData.append(
    //   'driver_national_insurance_number',
    //   values.driver_national_insurance_number
    // );
    // formData.append(
    //   'driver_national_insurance_expire_date',
    //   values.driver_national_insurance_expire_date
    // );
    // formData.append(
    //   'driver_iban_bank_account',
    //   values.driver_iban_bank_account
    // );

    formData.append('bank_name', values.bank_name);
    // formData.append('company_info', JSON.stringify(values.company_info));
    formData.append('driver_info', JSON.stringify(driverObj));
    formData.append('country_info', JSON.stringify(values.country_info));
    formData.append('state_info', JSON.stringify(values.state_info));
    formData.append('city_info', JSON.stringify(values.city_info));
    // formData.append('brand', values.brand);
    // formData.append('car_company', values.car_company);
    // formData.append('country', values.country);
    // formData.append('state', values.state);
    // formData.append('city', values.city);
    formData.append('user_type', values.user_type);
    formData.append('status', values.status);
    formData.append('booking_limit', values.booking_limit);
    formData.append('address', values.address);
    formData.append('password', values.password);
    formData.append('confirmPassword', values.confirmPassword);
    formData.append('documents', values.documents);
    formData.append('document', values.profile_picture);
    formData.append('document', values.driver_emirates_id_front);
    formData.append('document', values.driver_emirates_id_back);
    formData.append('document', values.driver_rta_front);
    formData.append('document', values.driver_rta_back);
    formData.append('document', values.driver_license_front);
    formData.append('document', values.driver_license_back);
    formData.append('document', values.driver_car_registration_front);
    formData.append('document', values.driver_car_registration_back);
    formData.append(
      'taxi_multi_array',
      JSON.stringify(values.taxi_multi_array)
    );
    formData.append('attached_vehicle', values.attached_vehicle);
    formData.append('taxi_no', values.taxi_no);
    formData.append('imageName', imagesName);

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/addManageDriver`,
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAddManageDriverSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editManageDriver(values, imagesName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();

    const obj = {
      shift_status: 'OUT',
      status: 'F',
      update_date:
        moment(new Date()).format('YYYY-MM-DD') +
        'T' +
        moment(new Date()).format('HH:mm:ss') +
        '.000Z'
    };

    const driverObj = {
      ...values.driver_info,
      ...obj
    };

    formData.append('_id', values._id);
    formData.append('driver_no', values.driver_no);
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('country_code', values.country_code);
    formData.append('password', values.password);
    formData.append('confirmPassword', values.confirmPassword);
    formData.append('gender', values.gender ? values.gender : 'M');
    formData.append('dob', values.dob ? values.dob : '');
    formData.append('driver_license_id', values.driver_license_id);
    // formData.append(
    //   'driver_license_expire_date',
    //   values.driver_license_expire_date
    // );
    formData.append('rta_card_id', values.rta_card_id);
    formData.append('rta_card_expiry_date', values.rta_card_expiry_date);
    // formData.append(
    //   'driver_national_insurance_number',
    //   values.driver_national_insurance_number
    // );
    // formData.append(
    //   'driver_national_insurance_expire_date',
    //   values.driver_national_insurance_expire_date
    // );
    // formData.append(
    //   'driver_iban_bank_account',
    //   values.driver_iban_bank_account
    // );
    formData.append('bank_name', values.bank_name);
    // formData.append('company_info', JSON.stringify(values.company_info));
    formData.append('company_id', JSON.stringify(values.company_id));
    // formData.append('driver_info', JSON.stringify(values.driver_info));
    formData.append('driver_info', JSON.stringify(driverObj));
    formData.append('country_info', JSON.stringify(values.country_info));
    formData.append('state_info', JSON.stringify(values.state_info));
    formData.append('city_info', JSON.stringify(values.city_info));
    // formData.append('brand', values.brand);
    // formData.append('car_company', values.car_company);
    // formData.append('country', values.country);
    // formData.append('state', values.state);
    // formData.append('city', values.city);
    formData.append('user_type', values.user_type);
    formData.append('status', values.status);
    formData.append('booking_limit', values.booking_limit);
    formData.append('address', values.address);
    formData.append('document', values.profile_picture);
    formData.append('documents', JSON.stringify(values.documents));
    formData.append('document', values.driver_emirates_id_front);
    formData.append('document', values.driver_emirates_id_back);
    formData.append('document', values.driver_rta_front);
    formData.append('document', values.driver_rta_back);
    formData.append('document', values.driver_license_front);
    formData.append('document', values.driver_license_back);
    formData.append('document', values.driver_car_registration_front);
    formData.append('document', values.driver_car_registration_back);
    formData.append(
      'taxi_multi_array',
      JSON.stringify(values.taxi_multi_array)
    );
    formData.append('attached_vehicle', values.attached_vehicle);
    formData.append('taxi_no', values.taxi_no);
    formData.append('imageName', imagesName);

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/editManageDriver`,
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditManageDriverSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverGetById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/driverGetById`,
        id
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverGetByIdSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//  manage Driver List

export function driverFilterCall(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/searchBYDriverManagerlistNew`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverFilterCallSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function driverFilterCallCsv(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/searchBYDriverManagerlistNew`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverFilterCallCsvSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Manage Driver Count filter

export function driverFilterCount(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/searchBYDriverManagerCount`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverFilterCountSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDriverStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/driverStatusUpdate`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateDriverStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function approveStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/taxisubscriptionapprove`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getApproveStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function holdStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/taxisubscriptionHold`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getHoldStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/taxisubscriptionCancel`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCancelStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ExportDriverList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/exportDriverStatus`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getExportDriverListSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// jackson end
export function manageCarList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${webAPI_URL}/manageCar/taxiList`);

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getManageListSucess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taxiListDetails(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/driver/taxiList`, obj);

      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getTaxiListSuccess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function locationQueueDriverListDetails(obj) {
  return async (dispatch) => {

    // console.log('obj:', obj);
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/manageLocation/queueDriverList`, obj);
      // console.log('response:', response);
      if (response.data.responseDate !== undefined) {
        dispatch(slice.actions.getLocationQueueDriverListSuccess(response.data.responseDate
          ));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function manageNewDriverList(fromDate, toDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      fromDate: fromDate,
      toDate: toDate
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/manageNewDriverList`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getManageNewDriverListSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function approvedDriverCheck(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    // const obj = {
    //   id: 12465,
    //   password: '666777888'
    // };
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/approveDriver`,
        value
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getApprovedDriverSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function disApprovedDriver(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      id: id.id
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/disApprovedDriver`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getDisApprovedDriverSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function exportDriverStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/exportDriverStatus`
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getExportDriverStatusSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function newManageDriverInfo(value) {
  return async (dispatch) => {
    const obj = {
      id: value
    };
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/newManageDriverInfo`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getNewManageDriverInfo(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// jackson end

export function manageLostData(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // const obj = {
    //   fromDate: '2023-03-01 00:00:00',
    //   toDate: '2023-06-05 23:59:59'
    // };
    const obj = {
      fromDate: moment(new Date(value.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment(new Date(value.toDate)).format('YYYY-MM-DD HH:mm:ss')
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/lostFoundReq`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getManageLostDataSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverInformation(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/driverInfo`,
        value
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverInformationSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverLogoutByAdmin(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/logoutByAdmin`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverLogoutSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverCurrentInfo(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/driverCurrentStatus`,
        value
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverCurrentInfoSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zonesInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/listCustomZones`
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getZonesInfoSuccess(response.data.responseData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeDriverPassword(id, password) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      id: id,
      password: password
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/changePassword`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getChangDriverPasswordSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function shiftHistory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = { userID: JSON.stringify(id), clientID: 1, companyID: 7 };

    try {
      const response = await axios.post(
        'http://15.184.158.127:8809/api/v1/shiftCountByID',
        obj
      );
      if (response.data.detail !== undefined) {
        dispatch(slice.actions.getShiftHistorySuccess(response.data.detail));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function referralHistory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = { driverId: id };

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/referralHistory`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getReferralHistorySuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function completeTripList(id, dates) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      driverId: id,
      fromDate: dates.fromDate + ' 00:00:00',
      toDate: dates.toDate + ' 23:59:00'
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/completeTripList`,
        obj
      );
      if (response.data.detail !== undefined) {
        dispatch(
          slice.actions.getCompleteTripListSuccess(response.data.detail)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function carListAll(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status,
      company: value.company,
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/listTaxi`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getCarListAllSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function carListAllCsv(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status.trim(),
      company: value.company,
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/listTaxi`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getCarListCsvAllSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageCorporateList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status.trim(),
      company: value.company,
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/manageCorporateList`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getCorporateListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageZoneGroupList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status.trim(),
      corporateId: parseInt(value.corporateId),
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/manageZoneGroup`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getZoneGroupSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageZoneGroupStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/zoneGroupStatusChange`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getZoneGroupStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageZoneGroupListCSV(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status.trim(),
      corporateId: parseInt(value.corporateId),
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/manageZoneGroup`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(slice.actions.getZoneGroupCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageZoneList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/manageZone`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getManageZoneListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function manageZoneListCsv(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/manageZone`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getManageZoneListCsvSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function changeZoneStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/changeZoneStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getChangeZoneStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function manageAddZone(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/addZone`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getManageAddZoneSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function manageCorporateListCSV(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status.trim(),
      company: value.company,
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/manageCorporateList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCorporateListCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function corporatePackageList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    console.log('value:', value);
    const obj = {
      status: value.status.trim(),
      company: value.company,
      corporateId: value.corporateId,
      // keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/corporatePackageList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCorporatePackageListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetLocationQueueDriverLogs(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      kioskId: value.locationName,
      driverId: value.driverId,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationQueueLogs`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationQueueLogListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetLocationEntryandExitLogs(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      kioskId: value.kioskId,
      driverId: value.driverId
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationEntryExitLogs`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getLocationEntryExitLogListSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function GetLocationQueueDriverLogsCSV(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      kioskId: value.locationName,
      driverId: value.driverId,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationQueueLogs`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationQueueLogSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Promocode start
export function getPromocodeDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      startDate: value.startDateFrom,
      endDate: value.startDateTo ? value.startDateTo : '',
      expiryStartDate: value.expiryDateFrom,
      expiryEndDate: value.expiryDateTo,
      promocode: value.promocode,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/promocode/promocodeList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPromocodeListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromocodeDetailsCSV(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      startDate: value.startDateFrom,
      endDate: value.startDateTo ? value.startDateTo : '',
      expiryStartDate: value.expiryDateFrom,
      expiryEndDate: value.expiryDateTo,
      promocode: value.promocode,
      page: value.page,
      limit: value.limit,
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/promocode/promocodeList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPromocodeListCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPromocode(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      startDate: value.startDate,
      endDate: value.endDate ?  value.endDate : '',
      passenger: value.passenger,
      promocodeType: value.promocodeType,
      promocode: value.promocode,
      limitPerPassenger: value.limitPerPassenger,
      promocodeMaximumLimit: value.promocodeMaximumLimit,
      discountType: value.discountType,
      discount: value.discount,
      flatAmount: value.flatAmount,
      maximumDiscount: value.maximumDiscount,
      mobileAppVisibility: value.mobileAppVisibility,
      serviceType: value.serviceType
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/promocode/addPromocode`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddPromocodeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editPromocode(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      _id: value._id,
      startDate: value.startDate,
      endDate: value.endDate ? value.endDate : '',
      promocodeType: value.promocodeType,
      promocode: value.promocode,
      limitPerPassenger: value.limitPerPassenger,
      promocodeMaximumLimit: value.promocodeMaximumLimit,
      discountType: value.discountType,
      discount: value.discount,
      flatAmount: value.flatAmount,
      maximumDiscount: value.maximumDiscount,
      mobileAppVisibility: value.mobileAppVisibility,
      usedPromocodeCount: value.usedPromocodeCount,
      unusedPromocodeCount: value.unusedPromocodeCount,
      serviceType: value.serviceType
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/promocode/editPromocode`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getEditPromocodeSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromocodeInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/promocode/getPromocodeInfo`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPromocodeInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromocodeService() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${webAPI_URL}/promocode/serviceList`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPromocodeServiceSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Promocode end

export function getCustomZoneList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/customzone/list`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateCustomZoneStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/changeStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateCustomZoneStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function customZoneAdd(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/customzone/add`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneAddSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function customZoneEditList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/customzone/edit`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneEditListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function customZoneEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/customzone/update`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function customZoneDriverList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/driverListInsideZones`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneDriverListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function sendPushNotificationToDrivers(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/sendPushNotificationToDrivers`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getSendPushNotificationToDriversSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function customZoneUpdateDriverList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/updateDriverList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateDriverListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function customZoneDriversList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/driverList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomZoneDriverListsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDriverList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(
        `${webAPI_URL}/newBooking/driverList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverListDetailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomZoneModelFares(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/modelFares`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getCustomZoneModelFaresDetailsSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLocationCustomFare(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/kioskCustomiseFareDetail`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationCustomFareSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLocationCustomFareAddEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/addCustomizeFare`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getLocationCustomFareAddEditSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function customFareAddEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/customzone/updateModelFares`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomFareAddEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Custom zone End
export function updatePackageStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/changeCorporatePackageStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCorporatePackageStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addCorporatePackage(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/addCorporatePackage`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddCorporatePackageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editCorporatePackage(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/updatePackage`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getEditCorporatePackageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPackageDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/getPackageDetail`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPackageDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Passenger Package start
export function addPassengerPackage(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/addPassengerPackage`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addPassengerPackageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updatePassengerPackage(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/updatePassengerPackage`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updatePassengerPackageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passengerPackageList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/passengerPackageList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.passengerPackageListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function passengerPackageListSCV(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/passengerPackageList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.passengerPackageListCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPassengerPackageDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/getPassengerPackageDetail`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerPackageDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function changePassengerPackageStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/packages/changePassengerPackageStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.changePassengerPackageStatusSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// Passenger Package end

// SMS Template Start

export function addSmsTemplate(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/smsTemplate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addSmsTemplateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSmsTemplate(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/getSmsTemplate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getSmsTemplateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSmsTemplateLists(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/allSmsTemplate`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getSmsTemplateListsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function UpdateSmsTemplateStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.UpdateSmsTemplateStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// SMS Template End

// Mail setting Start

export function addMailSetting(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateSmtpConfig`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addMailSettingSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function adminUpdateSmtpConfig(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/adminUpdateSmtpConfig`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.adminUpdateSmtpConfigSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSmsSettings(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateSmsSettings`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateSmsSettingsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateAdminSmsSettings(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/adminUpdateSmsConfig`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateAdminSmsSettingsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMailSetting(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateSmtpConfig`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateMailSettingSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEditSocialNetworks(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateSocialNetworkSettings`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addEditSocialNetworksSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSiteSettingsDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateSiteSettingsDetail`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateSiteSettingsDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGoogleMapSettingsDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/googleMapSettingsDetail`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getGoogleMapSettingsDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGoogleMapSettings(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateGoogleMapSettings`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateGoogleMapSettingsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSmtpInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/smtpInfo`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getSmtpInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSiteSettingsDetail() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/siteSettingsDetail`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getSiteSettingsDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSocialSettingsDetail() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/socialNetworkSettingsDetail`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getSocialSettingsDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminSmtpInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/adminSmtpInfo`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getAdminSmtpInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getSmsInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/smsInfo`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getSmsInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminSmsInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/adminSmsInfo`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getAdminSmsInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Country
export function getCountryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/countryList`);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCountryListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountryDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/countryDetail`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCountryDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addState(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/addState`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddStateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateState(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateState`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateStateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStateDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/stateDetail`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getStateDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStateList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/manageStateList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getStateListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeStateStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/changeStateStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getChangeStateStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Country state city (CSC)- default
export function changeCSCDefault(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/changeDefault`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCSCStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// City

export function getCityList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/manageCityList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCityListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeCityStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/changeCityStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getChangeCityStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCity(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/addCity`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddCitySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCityDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/cityDetail`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCityDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCity(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/updateCity`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateCitySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// state

export function getStateLists(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/stateList`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getStateListsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// City
export function getCityLists(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/settings/cityList`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCityListsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageCountryList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/manageCountryList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.manageCountryListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateCountry(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/updateCountry`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateCountrySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSmtpConfigList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/smtpConfigList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getSmtpConfigListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSmsConfigList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/settings/smsConfigList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getSmsConfigListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Mail setting Edn

export function getModelFareList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/companyfare/modelFareList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getModelFareListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getFareDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/companyfare/modelFareDetail`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getFareDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addFareDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/companyfare/addFare`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addFareSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateFareDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/companyfare/updateFare`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateFareSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCorporateDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/getCorporateDetail`,
        value
      );
      if (response.data.corporateDetails !== undefined) {
        console.log('corporateDetail', response.data.responseData);
        dispatch(
          slice.actions.getCorporateDetailSuccess(
            response.data.corporateDetails
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getLocationList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/manageLocationList`,
        value
      );
      if (response.data !== undefined) {
        console.log('corporateDetail', response.data.responseData);
        dispatch(slice.actions.getLocationListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getLocationListDash(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationDashboard`,
        value
      );
      if (response.data !== undefined) {
        console.log('corporateDetail', response.data.responseData);
        dispatch(slice.actions.getLocationDashSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getLocationListCSV(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/manageLocationList`,
        value
      );
      if (response.data !== undefined) {
        console.log('corporateDetail', response.data.responseData);
        dispatch(slice.actions.getLocationListCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addLocation(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/addLocation`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddLocationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editLocation(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/editLocation`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getEditLocationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function locationDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationDetails`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationDetailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Tolls start
export function getTollList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageToll/manageTollList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getTollListsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTollListCSV(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageToll/manageTollList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getTollListsCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addToll(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageToll/addToll`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddTollSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editToll(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageToll/editToll`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getEditTollSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function tollDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageToll/getTollDetail`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getTollDetailssSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeTollStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageToll/changeTollStatus`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getChangeTollStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Tolls End

export function addDriverList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/addDriverList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddDriverListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addDriverInLocationQueueList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/addQueueToDriver`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddDriverInQueueListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function driverDetailsInLocationQueue(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationQueueDetails`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverDetailsInLocationQueueSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function driverList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/driverList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function locationDriverDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationDriverDetails`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getLocationDriverDetailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function multipleDropLocationList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/multipleDropLocationList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getMultipleDropLocationListSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addMultipleDropLocation(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/addMultipleDropLocation`,
        value
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getAddMultipleDropLocationSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverLocationDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/locationDetails`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverLocationListsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Manage Handover

export function fileUpload(value, folderName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/handover/uploadImageToTempFolder?folderName=${folderName}`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getfileDatasSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handoverList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/handover/list`, value);
      if (response.data !== undefined) {
        dispatch(slice.actions.gethandoverListSuccess(response.data));
      }
    } catch (error) {
      console.log('error:,', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handoverUserList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/handover/userList`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.gethandoverUserListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handoverDetails(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/handover/detail`, value);
      if (response.data !== undefined) {
        dispatch(slice.actions.gethandoverDetailsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handoverAssign(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/handover/assign`, value);
      if (response.data !== undefined) {
        dispatch(slice.actions.gethandoverAssignSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handoverUpdate(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/handover/update`, value);
      if (response.data !== undefined) {
        dispatch(slice.actions.gethandoverUpdateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCustomizeFare(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/addCustomizeFare`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddCustomizeFareSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function queueLocationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/queueLocationList`
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getQueueLocationListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function manageQueueLocationList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/manageQueueLocationList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getManageQueueLocationListSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function LocationGroupList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/georeport/locationGroupList`
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getLocationGroupListSucess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyLocationGroupList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newbooking/companyLocationGroups`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getCompanyLocationGroupListSucess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeLocationStatus(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/changeLocationStatus`,
        value
      );
      if (response.data !== undefined) {
        console.log('corporateDetail', response.data.responseData);
        dispatch(slice.actions.getLocationListStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addZoneGroupFareMap(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/addZoneGroupFareMap`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addZoneGroupFareMapSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addZoneGroupFareMapNew(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/addZoneGroupFareMapNew`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.addZoneGroupFareMapNewSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editZoneGroupFareMap(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/editZoneGroupFareMap`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.editZoneGroupFareMapSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editZoneGroupFareMapNew(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/editZoneGroupFareMapNew`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.editZoneGroupFareMapNewSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deletezoneGroupFareMap(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${webAPI_URL}/manageCorporate/deletezoneGroupFareMap`,
        { data: obj }
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.deleteZoneGroupFareMapSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletezoneGroupFareMapNew(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${webAPI_URL}/manageCorporate/deletezoneGroupFareMapNew`,
        { data: obj }
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.deleteZoneGroupFareMapNewSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteFareMap(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/deleteFareMap`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.deleteFareMapSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteFareMapNew(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/deleteFareMapNew`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.deleteFareMapNewSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zoneGroupFareMapList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/zoneGroupFareMapList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.zoneGroupFareMapListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zoneGroupFareMapListNew(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/zoneGroupFareMapListNew`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.zoneGroupFareMapListNewSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zoneCarModelList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/motorModelList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.zoneCarModelListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zoneCarModelListNew(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/carModelList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.zoneCarModelListNewSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fareZoneGroupList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/zoneGroupList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.zoneGroupListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Manage companies-arthi

export function manageCompany(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status,
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/companyList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getManageCompanySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageCompanyCsv(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      status: value.status,
      keyword: value.keyword,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/companyList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getManageCompanyCsvSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function subscriptionList(value, keyword) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      keyword: value.TaxiNumber,
      status: value.status,
      fromdate: moment(new Date(value.fromdate)).format('YYYY-MM-DD'),
      enddate: value.enddate ? moment(new Date(value.enddate)).format('YYYY-MM-DD') : '',
      dateFilterType: value.dateFilterType,
      page: value.page,
      limit: value.limit
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/taxiSubscriptionList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getSubscriptionListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addManageCar(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();
    formData.append('taxi_unique', values.taxi_unique);
    formData.append('taxi_no', values.taxi_no);
    formData.append('company_id', values.company_id);
    formData.append('car_make_id', values.car_make_id);
    formData.append('car_manufacturer_id', values.car_manufacturer_id);
    // formData.append('company_info', JSON.stringify(values.company_info));
    formData.append(
      'motor_model_info',
      values.motor_model_info
        ? JSON.stringify(values.motor_model_info)
        : undefined
    );
    formData.append('taxi_model_year', values.taxi_model_year);
    formData.append('taxi_owner_name', values.taxi_owner_name);
    formData.append('taxi_manufacturer', values.taxi_manufacturer);
    formData.append('taxi_colour', values.taxi_colour);
    formData.append('taxi_motor_expire_date', values.taxi_motor_expire_date);
    formData.append('taxi_last_service_date', values.taxi_last_service_date);
    formData.append('taxi_insurance_number', values.taxi_insurance_number);
    formData.append(
      'taxi_insurance_expire_date_time',
      values.taxi_insurance_expire_date_time
    );
    formData.append('taxi_pco_licence_number', values.taxi_pco_licence_number);
    formData.append('country_info', JSON.stringify(values.country_info));
    formData.append('state_info', JSON.stringify(values.state_info));
    formData.append('city_info', JSON.stringify(values.city_info));
    if (values.car_documents.length !== 0) {
      values.car_documents.map((item) => {
        formData.append('documents', item);
      });
    } else {
      formData.append('documents', values.car_documents);
    }

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/addManageCar`,
        formData
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddManageCarSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByCarList(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/getByIdTaxi`,
        value
      );
      if (response.data.taxi !== undefined) {
        dispatch(slice.actions.getGetByCarListSuccess(response.data.taxi));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editManageCar(values, oldImages) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();
    formData.append('_id', values._id);
    formData.append('company_id', values.company_id);
    formData.append('car_make_id', values.car_make_id);
    formData.append('car_manufacturer_id', values.car_manufacturer_id);
    formData.append('taxi_unique', values.taxi_unique);
    formData.append('taxi_no', values.taxi_no);
    // formData.append('company_info', JSON.stringify(values.company_info));
    formData.append(
      'motor_model_info',
      values.motor_model_info
        ? JSON.stringify(values.motor_model_info)
        : undefined
    );
    formData.append('taxi_model_year', values.taxi_model_year);
    formData.append('taxi_owner_name', values.taxi_owner_name);
    formData.append('taxi_manufacturer', values.taxi_manufacturer);
    formData.append('taxi_colour', values.taxi_colour);
    formData.append('taxi_motor_expire_date', values.taxi_motor_expire_date);
    formData.append('taxi_last_service_date', values.taxi_last_service_date);
    formData.append('taxi_insurance_number', values.taxi_insurance_number);
    formData.append(
      'taxi_insurance_expire_date_time',
      values.taxi_insurance_expire_date_time
    );
    formData.append('taxi_pco_licence_number', values.taxi_pco_licence_number);
    formData.append('country_info', JSON.stringify(values.country_info));
    formData.append('state_info', JSON.stringify(values.state_info));
    formData.append('city_info', JSON.stringify(values.city_info));
    formData.append('old_images', JSON.stringify(oldImages));
    if (values.car_documents.length !== 0) {
      values.car_documents.map((item) => {
        formData.append('documents', item);
      });
    } else {
      formData.append('documents', values.car_documents);
    }

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/updateTaxi`,
        formData
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getEditManageCarSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Santhosh Start

export function getCustomManufacturerList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/manufacturerList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomManufacturerSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomManufacturerAdd(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/addManufacturer`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomManufacturerAddSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomManufacturerEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/updateManufacturer`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCustomManufacturerEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomManufacturerEditList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/manufacturerInfo`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getCustomManufacturerEditListSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomManufacturerUpdateStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/changeManufacturerStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(
          slice.actions.getCustomManufacturerUpdateStatus(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getChangeCarMakeStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/changeCarMakeStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getChangeCarMakeStatus(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
///car make
export function getCustomCarMakeList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/carMakeList`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomCarMakeAdd(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/addMake`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeAddSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomCarMakeEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/updateMake`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakerEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomCarMakeEditList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/carMakeInfo`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeEditListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomCarMakeUpdateStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/changeManufacturerStatus`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarMakeUpdateStatus(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reportCarManufacturerList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/carManufacturerMake/allManufacturerList`
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getCarManufacturerListSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fileUploadCarMake(formData, value, folderName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (value.edit) {
        const response = await axios.post(
          `${webAPI_URL}/carManufacturerMake/uploadImageToTempFolder?folderName=${folderName}&type=${value.type}&edit=${value.edit}&_id=${value._id}`,
          formData
        );
        if (response.data !== undefined) {
          dispatch(slice.actions.getCarMakefileDatasSuccess(response.data));
        }
      } else {
        const response = await axios.post(
          `${webAPI_URL}/carManufacturerMake/uploadImageToTempFolder?folderName=${folderName}&type=${value.type}`,
          formData
        );
        if (response.data !== undefined) {
          dispatch(slice.actions.getCarMakefileDatasSuccess(response.data));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Santhosh End
export function editManageCoporate(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('carCompany', values.carCompany);
    formData.append('corporateName', values.corporateName);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('address', values.address);
    formData.append('fareSetting', values.fareSetting);
    formData.append('mailSetting', values.mailSetting);
    formData.append('corporateCountryCode', values.corporateCountryCode);
    formData.append('type', values.type);
    formData.append('loc', JSON.stringify(values.loc));
    formData.append('newPassword', values.newPassword);
    formData.append('confirmPassword', values.confirmPassword);
    formData.append('corporateLogo', values.corporateLogo);
    formData.append('tradeLicense', values.tradeLicense);
    formData.append('contractDocument', values.contractDocument);

    formData.append('document', values.corporateLogoImage);
    formData.append('document', values.tradeLicenseImage);
    formData.append('document', values.contractDocImage);

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/updateCorporate`,
        formData
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getEditManageCorporateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addCorporateNew(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();
    formData.append('carCompany', values.carCompany);
    formData.append('corporateName', values.corporateName);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('address', values.address);
    formData.append('loc', JSON.stringify(values.loc));
    formData.append('fareSetting', values.fareSetting);
    formData.append('mailSetting', values.mailSetting);
    formData.append('corporateCountryCode', values.corporateCountryCode);
    // formData.append('type', values.type);
    formData.append('password', values.password);
    formData.append('confirmPassword', values.confirmPassword);

    formData.append('corporateLogo', values.corporateLogo);
    formData.append('tradeLicense', values.tradeLicense);
    formData.append('contractDocument', values.contractDocument);

    formData.append('document', values.corporateLogoImage);
    formData.append('document', values.tradeLicenseImage);
    formData.append('document', values.contractDocImage);

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/addCorporate`,
        formData
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddManageCorporateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function manageCorporateDashboard(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/corporateDashboard`,
        values
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getManageCorpDashSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Passenger Start
export function getPassengerLists(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/passenger/list`, values);
      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPassengerListsCSV(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/passenger/list`, values);
      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerListsCVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePassengerStatus(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/updateStatus`,
        values
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updatePassengerStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateWalletAmount(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/updateWalletAmount`,
        values
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.updateWalletAmountSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPasengerInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/passenger/info`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getPassengerInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passengerResetOTP(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/passenger/resetOtpCount`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.resetOtpCountSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passengerEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/passenger/edit`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.passengerEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Passenger End

export function addZoneGroup(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/addZoneGroup`,
        values
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getAddZoneGroupSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zoneGroupList(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/listZones`,
        values
      );
      if (response.data !== undefined) {
        console.log('zoneList', response.data);
        dispatch(slice.actions.getListZoneSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function zoneGroupListForEdit(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/listZonesNew`,
        values
      );
      if (response.data !== undefined) {
        console.log('zoneList', response.data);
        dispatch(slice.actions.getListForEditZoneSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getZoneGroupDetail(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/zoneGroupDetail`,
        values
      );
      if (response.data !== undefined) {
        console.log('zoneList', response.data);
        dispatch(slice.actions.getZoneDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editZoneGroup(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/editZoneGroup`,
        values
      );
      if (response.data !== undefined) {
        console.log('zoneList', response.data);
        dispatch(slice.actions.editZoneGroupSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editManageZone(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/editZone`,
        values
      );
      if (response.data !== undefined) {
        console.log('zoneList', response.data);
        dispatch(slice.actions.editManageZoneSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEditManageZone(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/zoneDetail`,
        values
      );
      if (response.data !== undefined) {
        console.log('zoneList', response.data);
        dispatch(slice.actions.editManageZoneDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCorporateStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCorporate/corporateStatusUpdate`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateCorporateStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCarStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/updateTaxiStatusReason`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateCarStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taxiInfoRecords(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      id: values
    };
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/taxiInfo`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getTaxiInfoRecordsSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taxiCompleteList(value, dates) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      id: value,
      fromDate: dates.fromDate + ' 00:00:00',
      toDate: dates.toDate + ' 23:59:00'
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/completeTaxiList`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getTaxiCompleteListSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function taxiSubscriptionList(value, dates) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      id: value
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCar/taxiSubscriptionList`,
        obj
      );
      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getTaxiSubscriptionListSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyAndCountryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/assigned/carCompany`);

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getCompanyAndCountryListSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function assignDriverCar(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/assigned/assignDriverCar`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.assignDriverCarSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverAndTaxiList(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const obj = {
      companyId: values
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/assigned/driverAndTaxiList`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getDriverAndTaxiListSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function assingedCarList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    // const obj = {
    //   companyId: values.companyId,
    //   status: values.status
    // };
    try {
      const response = await axios.post(
        `${webAPI_URL}/assigned/assignCarList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAssingedCarListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function assingedCarListCSV(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    // const obj = {
    //   companyId: values.companyId,
    //   status: values.status
    // };
    try {
      const response = await axios.post(
        `${webAPI_URL}/assigned/assignCarList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAssingedCarListCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function assingedCarListCounts(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/assigned/assignCarListCounts`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getAssingedCarListCountsSuccess(
            response.data.responseData
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAssignedCarStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/assigned/assignedCarStatusUpdate`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getUpdateAssignedCarStatusSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// company list common arthi

export function reportCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/report/companyAllList`);

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getReportCompanyListSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reportOtherList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/allTransactionFilter`,
        obj
      );

      if (response.data.responseData !== undefined) {
        dispatch(
          slice.actions.getReportOtherListSuccess(response.data.responseData)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reportFilterList(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      count: values.count,
      dispatcherId: values.dispatcherId,
      carId: values.carId,
      carManufacturerId: values.carManufacturerId,
      carMakeId: values.carMakeId,
      driverId: values.driverId,
      driverUniqueId: values.driverUniqueId,
      passengerId: values.passengerId,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.endDate
        ? moment(new Date(values.endDate)).format('YYYY-MM-DD HH:mm:ss')
        : '',
      paymentType: values.paymentType,
      // paymentMode: '',
      driverStatus: values.driverStatus,
      tripId: values.tripId,
      transactionId: values.transactionId,
      distance: values.distance,
      emiratesId: values.emiratesId,
      tripType: values.tripType,
      locationName: values.locationName,
      corporateName: values.corporateName,
      createdFrom: values.createdFrom,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/transactionAllFilter`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getReportFilterListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reportFilterListCsv(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      count: values.count,
      dispatcherId: values.dispatcherId,
      carId: values.carId,
      driverId: 0,
      driverUniqueId: values.driverUniqueId,
      passengerId: values.passengerId,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.endDate ? moment(new Date(values.endDate)).format('YYYY-MM-DD HH:mm:ss') : '',
      paymentType: values.paymentType,
      // paymentMode: '',
      driverStatus: values.driverStatus,
      tripId: values.tripId,
      transactionId: values.transactionId,
      distance: values.distance,
      emiratesId: values.emiratesId,
      tripType: values.tripType,
      locationName: values.locationName,
      corporateName: values.corporateName,
      createdFrom: values.createdFrom,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/transactionAllFilter`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getReportFilterListCsvSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// transactionAllFilterHistory

export function historyReportList(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      count: values.count,
      dispatcherId: values.dispatcherId,
      carId: values.carId,
      carManufacturerId: values.carManufacturerId,
      carMakeId: values.carMakeId,
      driverId: values.driverId,
      driverUniqueId: values.driverUniqueId,
      passengerId: values.passengerId,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.endDate
        ? moment(new Date(values.endDate)).format('YYYY-MM-DD HH:mm:ss')
        : '',
      paymentType: values.paymentType,
      // paymentMode: '',
      driverStatus: values.driverStatus,
      tripId: values.tripId,
      transactionId: values.transactionId,
      distance: values.distance,
      emiratesId: values.emiratesId,
      tripType: values.tripType,
      locationName: values.locationName,
      corporateName: values.corporateName,
      createdFrom: values.createdFrom,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/transactionAllFilterHistory`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.gethistoryReportListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function historyReportListCSV(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      count: values.count,
      dispatcherId: values.dispatcherId,
      carId: values.carId,
      driverId: 0,
      driverUniqueId: values.driverUniqueId,
      passengerId: values.passengerId,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.endDate ? moment(new Date(values.endDate)).format('YYYY-MM-DD HH:mm:ss') : '',
      paymentType: values.paymentType,
      // paymentMode: '',
      driverStatus: values.driverStatus,
      tripId: values.tripId,
      transactionId: values.transactionId,
      distance: values.distance,
      emiratesId: values.emiratesId,
      tripType: values.tripType,
      locationName: values.locationName,
      corporateName: values.corporateName,
      createdFrom: values.createdFrom,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/transactionAllFilterHistory`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.gethistoryReportListCSVSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function reportFilterAccount(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      count: values.count,
      dispatcherId: values.dispatcherId,
      carId: values.carId,
      driverId: values.driverId,
      driverUniqueId: values.driverUniqueId,
      passengerId: values.passengerId,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.endDate
        ? moment(new Date(values.endDate)).format('YYYY-MM-DD HH:mm:ss')
        : '',
      paymentType: values.paymentType,
      // paymentMode: '',
      driverStatus: 1,
      tripId: values.tripId,
      transactionId: values.transactionId,
      distance: values.distance,
      emiratesId: values.emiratesId,
      tripType: values.tripType,
      locationName: values.locationName,
      corporateName: values.corporateName,
      createdFrom: values.createdFrom,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/accountsReport`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getReportFilterAccountSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reportFilterAccountCsv(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      count: values.count,
      dispatcherId: values.dispatcherId,
      carId: values.carId,
      driverId: 0,
      driverUniqueId: values.driverUniqueId,
      passengerId: values.passengerId,
      fromDate: moment(new Date(values.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
      endDate: values.endDate ? moment(new Date(values.endDate)).format('YYYY-MM-DD HH:mm:ss') : '',
      paymentType: values.paymentType,
      // paymentMode: '',
      driverStatus: 1,
      tripId: values.tripId,
      transactionId: values.transactionId,
      distance: values.distance,
      emiratesId: values.emiratesId,
      tripType: values.tripType,
      locationName: values.locationName,
      corporateName: values.corporateName,
      createdFrom: values.createdFrom,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/accountsReport`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getReportFilterAccountCsvSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function supervisorFilterList(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      locationName: values.locationName,
      carId: values.carId,
      driverId: values.driverId,
      travelStatus: values.travelStatus,
      tripId: values.tripId,
      fromDate: values.fromDate,
      endDate: values.endDate ? values.endDate : '',
      locationFareType: values.locationFareType,
      quickOfflineTrips: values.quickOfflineTrips,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    // console.log('testst1', obj);
    try {
      const response = await axios.post(
        `${webAPI_URL}/report/supervisorReport`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorFilterListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function supervisorFilterListCsv(values, companyIdFromLocalStorage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const obj = {
      page: values.page,
      limit: values.limit,
      companyId: values.companyId,
      locationName: values.locationName,
      carId: values.carId,
      driverId: 0,
      travelStatus: values.travelStatus,
      tripId: values.tripId,
      fromDate: values.fromDate,
      endDate: values.endDate ? values.endDate : '',
      locationFareType: values.locationFareType,
      quickOfflineTrips: values.quickOfflineTrips,
      supervisorName: values.supervisorName,
      locationGroup: values.locationGroup
    };

    // console.log('testst1', obj);
    try {
      const response = await axios.post(
        `${webAPI_URL}/report/supervisorReport`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getSupervisorFilterListCsvSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function driverUnassigned(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newbooking/unAssignCorporateTrip`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverUnassignedSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function paginationList(paginationTransaction) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/transactionAllFilter`,
        paginationTransaction
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getPaginationSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function transactionEdit(paginationTransaction) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/updatePassengerTripFare`,
        paginationTransaction
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getTransactionEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function supervisorLogs(
  paginationTransaction,
  filter,
  companyIdFromLocalStorage
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    // const obj = {
    //   page: 0,
    //   limit: 50,
    //   companyId: companyIdFromLocalStorage!==null?companyIdFromLocalStorage:filter.companyId,
    //   count: filter.count,
    //   dispatcherId: filter.dispatcherId,
    //   keyword: filter.keyword,
    //   fromDate: moment(new Date(filter.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
    //   endDate: moment(new Date(filter.endDate)).format('YYYY-MM-DD HH:mm:ss'),
    //   locationName: filter.locationName
    // };

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/supervisorLogs`,
        paginationTransaction
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getSupervisorLogsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reportCount(paginationTransaction) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/dashboardReport`,
        paginationTransaction
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getReportCountSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Add Company - Jack
// export function addCompany(value) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     const formData = new FormData();
//     formData.append('first_name', value.first_name);
//     formData.append('last_name', value.last_name);
//     formData.append('document', value.document);
//     formData.append('email', value.email);
//     try {
//       const response = await axios.post(`${webAPI_URL}/manageCompany/addCompany`,value);

//       if (response.data !== undefined) {
//         dispatch(slice.actions.getAddCompanySuccess(response.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function addCompany(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const formData = new FormData();
    formData.append('firstName', value.firstName);
    formData.append('lastName', value.lastName);
    formData.append('email', value.email);
    formData.append('password', value.password);
    formData.append('confirmPassword', value.confirmPassword);
    formData.append('countryCode', value.countryCode);
    formData.append('mobileNumber', value.mobileNumber);
    formData.append('address', value.address);
    formData.append('companyName', value.companyName);
    formData.append('countryInfo', JSON.stringify(value.countryInfo));
    formData.append('stateInfo', JSON.stringify(value.stateInfo));
    formData.append('cityInfo', JSON.stringify(value.cityInfo));
    formData.append('primaryColor', value.primaryColor);
    formData.append('secondaryColor', value.secondaryColor);

    // Assuming value.document is an array of files
    if (value.document.length !== 0) {
      value.document.map((item) => {
        formData.append('document', item);
      });
    } else {
      formData.append('document', value.document);
    }

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/addCompany`,
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getAddCompanySuccess(response.data));
      } else {
        dispatch(
          slice.actions.hasError(new Error('No data received from server.'))
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyDetail(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/companyDetail`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditCompanyDetailSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editCompany(value, colorChanged) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData();
    formData.append('id', value.id);
    formData.append('firstName', value.firstName);
    formData.append('lastName', value.lastName);
    formData.append('email', value.email);
    formData.append('countryCode', value.countryCode);
    formData.append('mobileNumber', value.mobileNumber);
    formData.append('address', value.address);
    formData.append('companyName', value.companyName);
    formData.append('countryInfo', JSON.stringify(value.countryInfo));
    formData.append('stateInfo', JSON.stringify(value.stateInfo));
    formData.append('cityInfo', JSON.stringify(value.cityInfo));
    formData.append('primaryColor', value.primaryColor);
    formData.append('secondaryColor', value.secondaryColor);
    if (
      value.primaryColor !== colorChanged.primaryColor ||
      value.secondaryColor !== colorChanged.secondaryColor
    ) {
      formData.append('colorCodeChange', 1);
    }

    // formData.append('document', value.document);
    // Assuming value.document is an array of files
    if (value.document.length !== 0) {
      value.document.map((item) => {
        formData.append('document', item);
      });
    } else {
      formData.append('document', value.document);
    }
    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/editCompany`,
        formData
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditCompanySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyStatusUpdate(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/companyStatusUpdate`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyStatusUpdateSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyDashboard(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageCompany/companyDashboard`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyDashboardSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function userLogin(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/user/login`, obj);

      if (response.data !== undefined) {
        dispatch(slice.actions.getUserLoginSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function userAdd(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/user/add`, obj);

      if (response.data !== undefined) {
        dispatch(slice.actions.getUserAddSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyUserList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/user/list`, obj);

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyUserListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function companyUserListCsv(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/user/list`, obj);

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyUserListCsvSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editCompanyUser(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/user/getUserInfo`, obj);

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditCompanyUserSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCompanyUser(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/user/editUserInfo`, obj);

      if (response.data !== undefined) {
        dispatch(slice.actions.getUpdateCompanyUserSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function companyPermission(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/getUserRoleInfo`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyPermissionSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCompanyPermission(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/editUserRoleInfo`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(
          slice.actions.getUppdateCompanyPermissionSuccess(response.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function companyUserDashboard(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/userDashboard`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyUserDashboardSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCompanyUserStatus(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/user/updateUserStatus`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyUserStatusSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function activityLogsApi(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/activityLogsList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getactivityLogsSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(
      //   slice.actions.hasError({
      //     source: 'activityLogsRes',
      //     error: error.message
      //   })
      // );
    }
  };
}

export function getEditedJson(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/report/getEditedJson`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getEditedJsonSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // dispatch(
      //   slice.actions.hasError({
      //     source: 'activityLogsRes',
      //     error: error.message
      //   })
      // );
    }
  };
}

export function getDrivesReview(id) {
  const obj = { tripId: id };
  console.log('ID', id);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/driverRatingList`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getDriverReviewList(response.data));
        console.log('reviewData', response.data);
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}
export function PostDrivesReview(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/updateRating`,
        obj
      );

      if (response.data !== undefined) {
        // dispatch(slice.actions.getDriverReviewList(response.data));
        console.log('reviewData', response.data);
      }
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearError() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.hasError(null));
  };
}

export function getModelList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/manageCar/allModelList`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getModelListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function modelEditList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/manageCar/modelDetails`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getModelEditListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function modelAdd(obj) {

  console.log("obj",obj);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/manageCar/addModel`, obj);
      console.log("response",response);
      if (response.data !== undefined) {
        dispatch(slice.actions.getModelAddSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function modelEdit(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/manageCar/updateModel`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getModelEditSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fileUploadCarModel(formData, value, folderName) {
  // console.log("formData", formData);
  // console.log("value", value);
   console.log("folderName", folderName);

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (value.edit) {
        response = await axios.post(
          `${webAPI_URL}/manageCar/uploadImageToTempFolder?folderName=${folderName}`,
          formData
        );
        console.log("response", response);
        if (response.data !== undefined) {
          dispatch(slice.actions.getCarMakefileDatasSuccess(response.data));
        }
      } else {
        response = await axios.post(
          `${webAPI_URL}/manageCar/uploadImageToTempFolder?folderName=${folderName}`,
          formData
        );
        if (response.data !== undefined) {
          dispatch(slice.actions.getCarModelfileDatasSuccess(response.data));
        }
      }

      // Return the response data so it can be used in the component
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; // Re-throw the error so it can be caught in the component
    }
  };
}

export function fileUploadCarDriverTrip(formData, value, folderName) {
  // console.log("formData", formData);
  // console.log("value", value);
   console.log("folderName", folderName);

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (value.edit) {
        response = await axios.post(
          `${webAPI_URL}/newBooking/uploadImageToTempFolder?folderName=${folderName}`,
          formData
        );
        console.log("response", response);
        if (response.data !== undefined) {
          dispatch(slice.actions.getCarDriverDatasSuccess(response.data));
        }
      } else {
        response = await axios.post(
          `${webAPI_URL}/newBooking/uploadImageToTempFolder?folderName=${folderName}`,
          formData
        );
        if (response.data !== undefined) {
          dispatch(slice.actions.getCarDriverDatasSuccess(response.data));
        }
      }

      // Return the response data so it can be used in the component
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; // Re-throw the error so it can be caught in the component
    }
  };
}

export function uploadCarDriverImage(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`${webAPI_URL}/newBooking/saveDriverCarDocsToAssignedTrip`, obj);
      if (response.data !== undefined) {
        dispatch(slice.actions.getCarDriverImageUploadSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get ready queue driver uploaded images verify 
export function verifyDriverImage(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/manageLocation/updateDocStatus`,
        value
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getSecondaryDriverImagesVerifySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getSubscriptionDriverList(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/driverListForSubscription`,
        obj
      );

      // console.log("response" ,response);

      if (response.data !== undefined) {
        dispatch(slice.actions.getsubscriptionDriverListSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addDriverSubscription(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `${webAPI_URL}/driver/driverSubscription`,
        obj
      );

      console.log("addDriverSubscription response" ,response);

      if (response.data !== undefined) {
        dispatch(slice.actions.addSubscriptionDriverSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}