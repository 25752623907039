/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { PATH_APP_WEBBOOK } from './paths';
import React, { lazy, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';
import configMain from 'src/layouts/DashboardLayout/NavBar/configMain';
import configUser from 'src/layouts/DashboardLayout/NavBar/configUser';
// ----------------------------------------------------------------------
const userId = localStorage.getItem('userId');

const configRoutes = userId === '1' ? configMain : configUser;

// console.log("configUser:", configUser.href );
// console.log('PATH_APP_WEBBOOK:', PATH_APP_WEBBOOK.report.newBooking);

let endPath = '/404';

function getPathForHref(data, hrefToFind) {
  if (userId !== '1') {
    // Iterate over each object in the data array
    for (const item of data) {
      // If the item has an href property and it matches the hrefToFind
      // and the status is 1, return the href
      if (item.href && item.href === hrefToFind && item.status === 1) {
        return hrefToFind;
      }
      // If the item has nested items, recursively search them
      if (item.items) {
        // Recursively call getPathForHref with the nested items
        const path = getPathForHref(item.items, hrefToFind);
        // If path is not "/404", return the path
        if (path !== '/404') {
          return path;
        }
      }
    }
    // Add custom path here endPath
    endPath = '/404';
    // If the href with status 1 is not found in any of the items, return "/404"
    return '/404';
  } else {
    return hrefToFind;
  }
}

const isHrefExists = getPathForHref(configRoutes, '/app/manage/manageDriver');

// Log the result
// console.log('isHrefExists:', isHrefExists);
// console.log('AuthProtect:');
const WebBookerRoutes = {
  path: PATH_APP_WEBBOOK.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP_WEBBOOK.report.webBooking,
      component: lazy(() => import('src/views/ManageCorporateBookings'))
    },
    {
      exact: true,
      path: PATH_APP_WEBBOOK.report.corporateProfile,
      component: lazy(() => import('src/views/CorporateProfile'))
    },
    {
      exact: true,
      path: PATH_APP_WEBBOOK.root,
      component: () => <Redirect to={PATH_APP_WEBBOOK.report.root} />
    },
    {
      component: () => <Redirect to={endPath} />
    }
  ]
};

export default WebBookerRoutes;
