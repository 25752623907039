/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { getUserData } from 'src/redux/slices/authJwt';

// ----------------------------------------------------------------------

Logoloader.propTypes = {
  className: PropTypes.string
};

function Logoloader({ className, ...other }) {
  const userData = JSON.parse(getUserData());
  return (
    <Box
      component="img"
      alt="logo"
      // src="/static/brand/logoloader.svg"
      src={userData?.companyLogoUrl ? userData?.companyLogoUrl : "/static/brand/logoLoaderSand.svg"}
      height={40}
      className={className}
      {...other}
    />
  );
}

export default Logoloader;
