/* eslint-disable prettier/prettier */
// ----------------------------------------------------------------------

const borderRadius = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16
};

export default borderRadius;
