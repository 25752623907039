/* eslint-disable prettier/prettier */
// ----------------------------------------------------------------------

export default function Snackbar({ theme }) {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {}
      }
    }
  };
}
