/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { MAvatar } from 'src/theme';
import createAvatar from 'src/utils/createAvatar';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string,
  userData: PropTypes.func
};

function MyAvatar({ className, userData, ...other  }) {
  // const { user } = useAuth();
 
  return (
    <MAvatar
      src={userData.photoURL}
      alt={userData.displayName}
      color={userData.photoURL ? 'default' : createAvatar(userData.displayName).color}
      className={className}
      {...other}
    >
      {createAvatar(userData.displayName).name}
    </MAvatar>
  );
}

export default MyAvatar;
