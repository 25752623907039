/* eslint-disable prettier/prettier */
import { Icon } from '@iconify/react';
import Scrollbars from 'src/components/Scrollbars';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import doneAllFill from '@iconify-icons/eva/done-all-fill';
import { MBadge } from 'src/theme';
import bellFill from '@iconify-icons/eva/bell-fill';

import { useSnackbar } from 'notistack';
import closeFill from '@iconify-icons/eva/close-fill';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  markAllAsRead,
  getNotifications,
  dashboardNotification,
  notificationBookingId,
  notificationUnread
} from 'src/redux/slices/notifications';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListSubheader,
  SwipeableDrawer,
  Tab,
  ListItem,
  ListItemText,
  Chip
} from '@material-ui/core';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { MIconButton } from 'src/theme';
import Label from '../../../../components/label';
import PropTypes from 'prop-types';
import '../NotificationSound/style.css';
import { Stack } from '@mui/material';
import { getCompanyId } from 'src/redux/slices/authJwt';

const useStyles = makeStyles((theme) => ({
  root: {},
  listSubheader: {
    ...theme.typography.overline,
    lineHeight: 'unset',
    textTransform: 'uppercase',
    padding: theme.spacing(1, 2.5)
  },
  isUnRead: {
    backgroundColor: theme.palette.action.selected
  }
}));

Notifications.propTypes = {
  notificationDatas: PropTypes.object.isRequired,
  playSound: PropTypes.bool.isRequired,
  setPlaySoundB2c: PropTypes.func.isRequired
};

const childTab = ['Yet to Assign', 'Follow Up'];

const statusLabels = {
  0: 'Yet to assign',
  9: 'Driver assigned',
  12: 'Driver on the way',
  3: 'Driver arrived',
  2: 'Trip in progress',
  5: 'Waiting for payment',
  1: 'Trip completed',
  4: 'Passenger cancelled',
  6: 'Passenger cancelled',
  8: 'Dispatcher cancelled',
  13: 'Driver cancelled'
};

function Notifications({ notificationDatas, playSound, setPlaySoundB2c }) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const companyIdFromLocalStorage = Number(getCompanyId());

  // Destructuring the relevant data from the Redux store
  const { dashboardNotificationData } = useSelector(
    (state) => state.notifications
  );

  const [notificationData, setNotificationData] = useState(null);
  const [selectedTab, setSelectedTab] = useState('now');
  const [selectedTabChild, setSelectedTabChild] = useState('Yet to Assign');
  const [totalReadStatusOne, setTotalReadStatusOne] = useState(0);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    if (notificationDatas) {
      setNotificationData(notificationDatas);
    }
  }, [notificationDatas, selectedTab, selectedTabChild]);

  useEffect(() => {
    if (dashboardNotificationData) {
      // Ensure that b2cSection is defined before accessing its properties
      const b2cSection = notificationData?.b2c || {};
      let calculatedTotalReadStatusOne = 0;
      let visitedTripIds = new Set();
      // Iterate through the nested objects to calculate total read status
      Object.values(b2cSection).forEach((nestedObject) => {
        nestedObject.ids.forEach((item) => {
          if (item.readStatus === 0 && !visitedTripIds.has(item.tripId)) {
            calculatedTotalReadStatusOne++;
            visitedTripIds.add(item.tripId);
          }
        });
      });

      setTotalReadStatusOne(calculatedTotalReadStatusOne);
    }
  }, [dashboardNotificationData, notificationData]);

  const handleChangeTab = (event, newValue) => {
    setNotificationData(null);
    setSelectedTab(newValue);
  };
  const handleChangeChildTab = (event, newValue) => {
    setNotificationData(null);
    setSelectedTabChild(newValue);
  };

  const handleMarkAllAsRead = (id) => {
    let tripIds = id.map((item) => item.tripId);
    const obj = {
      tripIds: tripIds,
      readStatus: '1'
    };
    dispatch(notificationUnread(obj));
  };

  const handleClickRead = (id) => {
    console.log('handleClickRead:', id.tripId);
    const obj = {
      tripIds: [id.tripId],
      readStatus: '1'
    };
    dispatch(notificationUnread(obj));
    dispatch(dashboardNotification({companyId: companyIdFromLocalStorage}));
  };

  const handleViewBooking = (id, pickupTime, type) => {
    let tripId = { id: id };
    dispatch(notificationBookingId(tripId));
    dispatch(dashboardNotification({companyId: companyIdFromLocalStorage}));
    sessionStorage.setItem('notifyCurrentTab', JSON.stringify(type));
    sessionStorage.setItem('tripId', JSON.stringify(tripId.id));
    sessionStorage.setItem('date', JSON.stringify(pickupTime));
    sessionStorage.setItem('toDate', JSON.stringify(pickupTime));
    sessionStorage.setItem('viewBooking', JSON.stringify(1));
    history.push('/app/newBooking');
    setOpen(false);
  };


  const getIconColor = (type) => {
    if (type === 'now') return 'primary';
    if (type === 'today') return 'info';
    if (type === 'future') return 'warning';
    if (type === 'late') return 'error';
    return 'primary';
  };

  useEffect(() => {
    if (playSound) {
      const timeoutId = setTimeout(() => {
        setPlaySoundB2c(false);
      }, 2000);

      // Cleanup the timeout to avoid potential memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [playSound, setPlaySoundB2c]);

  const handleOpenCopySnackbar = () => {
    enqueueSnackbar('Copy success!', {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };

  const handleCloseCopySnackbar = () => {
    enqueueSnackbar('Copy failed!', {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };


  const handleCopyTripId = (tripId) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(tripId).then(
        () => {
          handleOpenCopySnackbar();
        },
        () => {
          handleCloseCopySnackbar();
        }
      );
    } else {
      // Fallback method
      const textArea = document.createElement('textarea');
      textArea.value = tripId;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          handleOpenCopySnackbar();
        } else {
          handleCloseCopySnackbar();
        }
      } catch (err) {
        handleCloseCopySnackbar();
      }

      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <Tooltip title="B2C">
        <MIconButton
          ref={anchorRef}
          onClick={() => setOpen(true)}
          color={isOpen ? 'primary' : 'default'}
        >
          <Typography variant="span" sx={{ fontSize: '9px' }}>
            B2C
          </Typography>
          <Badge badgeContent={totalReadStatusOne} color="error">
            <Icon
              className={`${playSound ? 'iconAnimation' : ''}`}
              icon={bellFill}
              width={20}
              height={20}
            />
          </Badge>
        </MIconButton>
      </Tooltip>

      <SwipeableDrawer
        width={500}
        open={isOpen}
        anchor="right"
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        PaperProps={{
          style: { width: 500 }
        }}
      >
        <Box sx={{ py: 2 }}>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 }
              }
            }}
          >
            {Object.keys(notificationData?.b2c || {}).map((key) => (
              <Tab
                key={key}
                value={key}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{key}</span>
                    {notificationData &&
                    notificationData?.b2c[key]?.unread != 0 ? (
                      <Label
                        color={getIconColor(key)}
                        style={{ marginLeft: 5, padding: '2px' }}
                      >
                        {notificationData && notificationData?.b2c[key]?.unread}
                      </Label>
                    ) : null}
                  </div>
                }
              />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {notificationData?.b2c &&
                notificationData.b2c[selectedTab] &&
                notificationData.b2c[selectedTab]?.ids && (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    You have {'  '}
                    {
                      notificationData.b2c[selectedTab]?.ids?.filter(
                        (id) => id.readStatus === 0
                      ).length
                    }{' '}
                    unread messages
                  </Typography>
                )}
            </Typography>
          </Box>

          {notificationData &&
            notificationData?.b2c[selectedTab]?.ids?.filter(
              (id) => id.readStatus === 0
            ).length > 0 && (
              <Tooltip title=" Mark all as read">
                <MIconButton
                  color="primary"
                  onClick={() =>
                    handleMarkAllAsRead(notificationData?.b2c[selectedTab]?.ids)
                  }
                >
                  <Icon icon={doneAllFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
            )}
        </Box>

        <Divider />
        <Box sx={{ py: 2 }}>
          <Tabs
            value={selectedTabChild}
            onChange={handleChangeChildTab}
            // variant="scrollable"
            centered
            // scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 }
              }
            }}
          >
            {childTab.map((key, index) => {
              return (
                <Tab
                  key={key}
                  value={key}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{key}</span>
                      <Label
                        color={getIconColor(key)}
                        style={{ marginLeft: 5, padding: '2px' }}
                      >
                        {notificationData &&
                          notificationData.b2c[selectedTab]?.ids?.filter((id) =>
                            key === 'Yet to Assign'
                              ? id?.travelStatus === 0
                              : id?.travelStatus !== 0
                          ).length}
                      </Label>
                    </div>
                  }
                />
              );
            })}
          </Tabs>
        </Box>

        <Box sx={{ height: { xs: 340, sm: 'auto' } }}>
          <Scrollbars>
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.listSubheader}
                >
                  Bookings (
                  {notificationData && notificationData.b2c[selectedTab]?.ids
                    ? notificationData.b2c[selectedTab]?.ids?.length
                    : 0}
                  )
                </ListSubheader>
              }
            >
              {selectedTabChild === 'Yet to Assign' ? (
                notificationData &&
                notificationData?.b2c[selectedTab]?.ids &&
                notificationData.b2c[selectedTab]?.ids.length !== 0 ? (
                  notificationData.b2c[selectedTab]?.ids
                    ?.filter((id) => id.travelStatus === 0)
                    .map((id) => (
                      <ListItem
                        button
                        to="#"
                        disableGutters
                        key={id.tripId}
                        component={RouterLink}
                        sx={{
                          px: 2.5,
                          py: 2,
                          backgroundColor: `${
                            id.readStatus !== 1
                              ? theme.palette.action.selected
                              : ''
                          }`,
                          position: 'relative'
                        }}
                      >
                        <ListItemText
                           onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickRead(id);
                          }}
                          primary={
                            <>
                              <Typography variant="subtitle2">
                                Trip Id: {id.tripId}
                                <CopyToClipboard text={id.tripId} sx={{width:'5%'}}>
                                  <ContentCopyIcon
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleCopyTripId(id.tripId);
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </CopyToClipboard>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  &nbsp; &nbsp; &nbsp;
                                  {id.message}
                                </Typography>
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  mt: 2
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  sx={{ height: '30px', width: 'auto' }}
                                  onClick={
                                    () => handleViewBooking(id.tripId, id.pickupTime, id.type)
                                    // handleViewBooking(id.tripId)
                                  }
                                >
                                  View Booking
                                </Button>
                                <Tooltip title="Pickup Time">
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  &nbsp;
                                  {id?.pickupTime}
                                </Typography>
                                </Tooltip>
                                <Chip
                                  size="small"
                                  label={statusLabels[id.travelStatus]}
                                  color="primary"
                                />
                              </Box>
                            </>
                          }
                        />
                        <MBadge
                          color="info"
                          variant="dot"
                          sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            display: `${id.readStatus !== 1 ? 'block' : 'none'}`
                          }}
                        ></MBadge>
                      </ListItem>
                    ))
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'capitalize'
                      }}
                    >
                      No Bookings Yet!
                    </Typography>
                  </Box>
                )
              ) : notificationData &&
                notificationData?.b2c[selectedTab]?.ids &&
                notificationData.b2c[selectedTab]?.ids.length !== 0 ? (
                notificationData.b2c[selectedTab]?.ids
                  ?.filter((id) => id.travelStatus !== 0)
                  .map((id) => (
                    <ListItem
                      button
                      to="#"
                      disableGutters
                      key={id.tripId}
                      component={RouterLink}
                      sx={{
                        px: 2.5,
                        py: 2,
                        backgroundColor: `${
                          id.readStatus !== 1
                            ? theme.palette.action.selected
                            : ''
                        }`,
                        position: 'relative'
                      }}
                    >
                      <ListItemText
                         onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleClickRead(id);
                        }}
                        primary={
                          <>
                            <Typography variant="subtitle2">
                              Trip Id: {id.tripId}

                              <CopyToClipboard text={id.tripId} sx={{width:'5%'}}>
                                  <ContentCopyIcon
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleCopyTripId(id.tripId);
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </CopyToClipboard>

                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: 'text.secondary',
                                  textTransform: 'capitalize'
                                }}
                              >
                                &nbsp; &nbsp; &nbsp;
                                {id.message}
                              </Typography>
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{ height: '30px', width: 'auto' }}
                                onClick={
                                  // () => handleViewBooking(id.tripId)
                                  () => handleViewBooking(id.tripId, id.pickupTime, id.type)
                                }
                              >
                                View Booking
                              </Button>
                              <Tooltip title="Pickup Time">
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                      color: 'text.secondary',
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    &nbsp;
                                    {id?.pickupTime}
                                  </Typography>
                                </Tooltip>
                              <Chip
                                size="small"
                                label={statusLabels[id.travelStatus]}
                                color="primary"
                              />
                            </Box>
                          </>
                        }
                      />
                      <MBadge
                        color="info"
                        variant="dot"
                        sx={{
                          position: 'absolute',
                          right: '20px',
                          top: '20px',
                          display: `${id.readStatus !== 1 ? 'block' : 'none'}`
                        }}
                      ></MBadge>
                    </ListItem>
                  ))
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.secondary',
                      textTransform: 'capitalize'
                    }}
                  >
                    No Bookings Yet!
                  </Typography>
                </Box>
              )}
            </List>
          </Scrollbars>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default Notifications;
