/* eslint-disable prettier/prettier */
import localAxios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axiosInstanse';
import { RSLUrl } from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
  notifications: [],
  dashboardNotificationData: [],
  clearBookingIdData: ''
};

const { webAPI_URL } = RSLUrl;

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      const notifications = action.payload;
      state.notifications = notifications;
    },

    // MARK ALL AS READ
    markAllAsRead(state) {
      const markAll = state.notifications.map((notification) => {
        return {
          ...notification,
          isUnRead: false
        };
      });

      state.notifications = markAll;
    },

    // Live notification start
    getDashboardNotificationSucess(state, action) {
      state.isLoading = false;
      state.dashboardNotificationData = action.payload;
    },
    getNotificationUnreadSucess(state, action) {
      state.isLoading = false;
      state.notificationUnreadData = action.payload;
    },
    getNotificationBookingIdSucess(state, action) {
      state.isLoading = false;
      state.notificationBookingIdData = action.payload;
    },
    clearBookingIdSucess(state, action) {
      state.isLoading = false;
      state.clearBookingIdData = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { markAllAsRead } = slice.actions;

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await localAxios.get('/api/notifications');
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Notification live axios api Starts

export function dashboardNotification(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        // `${webAPI_URL}/newBooking/notificationLogsList`
        `${webAPI_URL}/newBooking/notificationLogsList`, obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getDashboardNotificationSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function notificationUnread(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${webAPI_URL}/newBooking/changeNotificationReadStatus`,
        obj
      );

      if (response.data !== undefined) {
        dispatch(slice.actions.getNotificationUnreadSucess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function notificationBookingId(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (obj !== undefined) {
        dispatch(slice.actions.getNotificationBookingIdSucess(obj));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearBookingId(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearBookingIdSucess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
