/* eslint-disable prettier/prettier */
// useSound.js
import { useEffect, useRef } from 'react';

const useSound = (audioFile) => {
  const audioRef = useRef(new Audio(audioFile));

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  const play = () => {
    audioRef.current.play();
  };

  const stop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  return { play, stop };
};

export default useSound;
