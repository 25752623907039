/* eslint-disable prettier/prettier */
import faker from 'faker';
import jwt from 'jsonwebtoken';
import mock from 'src/utils/mock';

import { codes } from 'src/utils/helpError';
import fakeRequest from 'src/utils/fakeRequest';

// ----------------------------------------------------------------------

const JWT_SECRET = 'minimal-secret-key';
const JWT_EXPIRES_IN = '5 days';

const users = [
  {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'RSL',
    email: 'access@royalsmartlimousine.com',
    password: 'rsl@2021',
    photoURL: '/static/images/avatars/avatar_default.jpg',
    phoneNumber: '+40 777666555',
    country: 'United States',
    address: '90210 Broadway Blvd',
    state: 'California',
    city: 'San Francisco',
    zipCode: '94116',
    userRole: 'app/dashboardMain',
    about: '',
    role: 'demo',
    isPublic: true,
    companyId: 0
  },
  {
    id: '8864c717-587d-472a-929a-8e5f298024da-7',
    displayName: 'RSL Company',
    email: 'tamilArasu@royalsmartlimousine.com',
    password: 'rsl@2021',
    photoURL: '/static/images/avatars/avatar_default.jpg',
    phoneNumber: '+40 777666555',
    country: 'United States',
    address: '90210 Broadway Blvd',
    state: 'California',
    city: 'San Francisco',
    zipCode: '94116',
    userRole: 'app/dashboardUser',
    about: '',
    role: 'demo',
    isPublic: true,
    companyId: 7
  }
];

// ----------------------------------------------------------------------

mock.onPost('/api/account/login').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { email, password, companyId } = JSON.parse(config.data);
    const user = users.find((_user) => _user.email === email);
    // console.log(user, 'user: Acc ');
    if (!user) {
      return [400, { message: codes.userNotFound.code }];
    }

    if (user.password !== password) {
      return [400, { message: codes.wrongPassword.code }];
    }

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [200, { accessToken, user }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/account/login').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { email, password } = JSON.parse(config.data);
    const user = users.find((_user) => _user.email === email);

    if (!user) {
      return [400, { message: codes.userNotFound.code }];
    }

    if (user.password !== password) {
      return [400, { message: codes.wrongPassword.code }];
    }

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [200, { accessToken, user }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onPost('/api/account/register').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { email, password, firstName, lastName } = JSON.parse(config.data);
    let user = users.find((_user) => _user.email === email);

    if (user) {
      return [400, { message: codes.emailAlreadyinUse.code }];
    }

    user = {
      id: faker.random.uuid(),
      displayName: firstName + ' ' + lastName,
      email,
      password,
      photoURL: null,
      phoneNumber: null,
      country: null,
      address: null,
      state: null,
      city: null,
      zipCode: null,
      about: null,
      role: 'user',
      isPublic: true
    };

    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [200, { accessToken, user }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onGet('/api/account/my-account').reply((config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Authorization token missing' }];
    }

    const verifyUserId = Number(localStorage.getItem('userId'));

    // console.log('verifyUserId:', verifyUserId);

    const accessToken = Authorization.split(' ')[1];
    const { userId } = jwt.verify(accessToken, JWT_SECRET);

    // console.log('Decoded userId:', userId);
    // console.log('Users Array:', users);

    // const user = users.find((_user) => _user.id === userId);
    const user = verifyUserId;
    // console.log('Found User:', user);

    if (!user) {
      return [401, { message: 'Invalid authorization token' }];
    }

    return [200, { user }];
  } catch (error) {
    console.error('JWT verification error:', error.message);
    return [500, { message: 'Internal server error' }];
  }

  //  catch (error) {
  //   console.error(error);
  //   return [500, { message: 'Internal server error' }];
  // }
});
