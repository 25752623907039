/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { logo } from 'src/themeConfig';
import { useEffect } from 'react';  
import { getCompanyInfo } from 'src/redux/slices/settings';

Logo.propTypes = {
  className: PropTypes.string
};

function Logo({ className, ...other }) {
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(getCompanyInfo({ domainName: window.location.hostname }));
  }, [dispatch]);  
  const { companyInfoRes } = useSelector((state) => state.settings);


  const logoUrl = companyInfoRes?.responseData?.companyLogoUrl || logo;

  console.log("companyInfoRes", companyInfoRes);

  return (
    <>
      <Box
        component="img"
        alt="logo"
        src={logoUrl}
        height={60}
        className={className}
        {...other}
      />
    </>
  );
}

export default Logo;
