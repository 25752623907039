/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { PATH_APP } from './paths';
import React, { lazy, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';
import configMain from 'src/layouts/DashboardLayout/NavBar/configMain';
import configUser from 'src/layouts/DashboardLayout/NavBar/configUser';
import ValidRoutes, { getFilteredRoutes } from './validRoutes';
// ----------------------------------------------------------------------

// const userId = localStorage.getItem('userId');
// let navConfigData = JSON.parse(localStorage.getItem('menuAccess'));
// const configRoutes = userId === '1' ? configMain : configUser;
let endPath = '/404';

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.report.engine,
      component: lazy(() => import('src/views/Reports'))
    },
    {
      exact: true,
      path: PATH_APP.report.taxiDispatch,
      component: lazy(() => import('src/views/TaxiDispatch'))
    },
    {
      exact: true,
      path: PATH_APP.report.settle,
      component: lazy(() => import('src/views/DriverApp'))
    },
    {
      exact: true,
      path: PATH_APP.report.settleDetail,
      component: lazy(() => import('src/views/DriverApp/settle'))
    },
    {
      exact: true,
      path: PATH_APP.report.secondReport,
      component: lazy(() => import('src/views/secondReport'))
    },
    {
      exact: true,
      path: PATH_APP.report.ramgps
      // component: lazy(() => import('src/views/blog/NewPostView'))
    },
    {
      exact: true,
      path: PATH_APP.report.fuel,
      component: lazy(() => import('src/views/Reports/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.report.travelSheet,
      component: lazy(() => import('src/views/Reports'))
    },
    {
      exact: true,
      path: PATH_APP.report.travel,
      component: lazy(() => import('src/views/Reports'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.report.root} />
    },
    {
      exact: true,
      path: PATH_APP.report.history,
      component: lazy(() => import('src/views/mapComponent/history'))
    },
    {
      exact: true,
      path: PATH_APP.report.model,
      component: lazy(() => import('src/views/ManageModel'))
    },
    {
      exact: true,
      path: PATH_APP.report.editModel,
      component: lazy(() => import('src/views/ManageModel/editModelIndex'))
    },
    {
      exact: true,
      path: PATH_APP.report.driverStatus,
      component: lazy(() => import('src/views/DriverReport'))
    },
    {
      exact: true,
      path: PATH_APP.report.supervisor,
      component: lazy(() => import('src/views/Supervisor'))
    },
    {
      exact: true,
      path: PATH_APP.report.supervisorInfo,
      component: lazy(() => import('src/views/Supervisor/SupervisorInfo'))
    },
    {
      exact: true,
      path: PATH_APP.report.addEditSupervisor,
      component: lazy(() => import('src/views/Supervisor/addEditSupervisor'))
    },
    {
      exact: true,
      path: PATH_APP.report.supervisorDetails,
      component: lazy(() => import('src/views/Supervisor/supervisorDetails'))
    },
    {
      exact: true,
      path: PATH_APP.report.exitRecord,
      component: lazy(() => import('src/views/ExitRecord'))
    },
    {
      exact: true,
      path: PATH_APP.report.geoReport,
      component: lazy(() => import('src/views/GeoReport'))
    },
    {
      exact: true,
      path: PATH_APP.report.geoReportDetails,
      component: lazy(() => import('src/views/GeoReport/detailsPage'))
    },
    {
      exact: true,
      path: PATH_APP.report.pushNotification,
      component: lazy(() => import('src/views/Rider'))
    },
    {
      exact: true,
      path: PATH_APP.report.pushNotificationDetails,
      component: lazy(() => import('src/views/Rider/detailsPage'))
    },
    {
      exact: true,
      path: PATH_APP.report.timeZone,
      component: lazy(() => import('src/views/Kiosk'))
    },
    {
      exact: true,
      path: PATH_APP.report.timeZoneNotification,
      component: lazy(() => import('src/views/Kiosk/timeZoneDetails'))
    },
    //live booking
    {
      exact: true,
      // path: getPathForHref(configRoutes, PATH_APP.report.newBooking),
      path: PATH_APP.report.newBooking,
      component: lazy(() => import('src/views/NewBooking'))
    },
    {
      exact: true,
      path: PATH_APP.report.driverDocument,
      component: lazy(() => import('src/views/DriverDocumenst'))
    },
    {
      exact: true,
      path: PATH_APP.report.locationGroup,
      component: lazy(() => import('src/views/LocationGroup'))
    },
    {
      exact: true,
      path: PATH_APP.report.gMaps,
      component: lazy(() => import('src/views/GMaps'))
    },
    {
      exact: true,
      path: PATH_APP.report.zone,
      component: lazy(() => import('src/views/Zone'))
    },
    {
      exact: true,
      path: PATH_APP.report.locationGroupAdd,
      component: lazy(() => import('src/views/LocationGroup/Add'))
    },
    {
      exact: true,
      path: PATH_APP.report.locationGroupEdit,
      component: lazy(() => import('src/views/LocationGroup/Edit'))
    },
    {
      exact: true,
      // path: getPathForHref(configRoutes,PATH_APP.report.manageDriver) ,
      path: PATH_APP.report.manageDriver,
      component: lazy(() => import('src/views/ManageDriver'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageDriverAdd,
      // component: lazy(() => import('src/views/app/ChatView/index')),
      component: lazy(() => import('src/views/ManageDriver/Add'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageDriverEdit,
      // component: lazy(() => import('src/views/app/ChatView/index')),
      component: lazy(() => import('src/views/ManageDriver/Edit'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageDriverInfo,
      component: lazy(() => import('src/views/ManageDriver/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageSubscriptionInfo,
      component: lazy(() => import('src/views/Taxi Subscription List/Edit'))
    },

    // jackson start
    {
      exact: true,
      path: PATH_APP.report.manageNewDrivers,
      component: lazy(() => import('src/views/ManageNewDriver'))
    },
    {
      exact: true,
      path: PATH_APP.report.registeredDriverInfo,
      component: lazy(() =>
        import('src/views/ManageNewDriver/RegisteredDriverInfo')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.editCars,
      component: lazy(() => import('src/views/ManageCars/editCar'))
    },
    {
      exact: true,
      path: PATH_APP.report.exportDriverList,
      component: lazy(() => import('src/views/ExportDriverStatus'))
    },
    {
      exact: true,
      path: PATH_APP.report.lostFoundList,
      component: lazy(() => import('src/views/ManageLossFound'))
    },

    // Company User
    {
      exact: true,
      path: PATH_APP.report.managePermission,
      component: lazy(() => import('src/views/Permission/managePermission'))
    },
    {
      exact: true,
      path: PATH_APP.report.companyList,
      component: lazy(() => import('src/views/Permission'))
    },
    {
      exact: true,
      path: PATH_APP.report.editCompany,
      component: lazy(() => import('src/views/Permission/editCompany'))
    },
    {
      exact: true,
      path: PATH_APP.report.addCompany,
      component: lazy(() => import('src/views/Permission/addCompany'))
    },
    // ManageHandover
    {
      exact: true,
      path: PATH_APP.report.manageHandover,
      component: lazy(() => import('src/views/ManageHandover'))
    },
    {
      exact: true,
      path: PATH_APP.report.addEditHandover,
      component: lazy(() => import('src/views/ManageHandover/AddEditHandover'))
    },

    // manageLocation
    {
      exact: true,
      path: PATH_APP.report.manageLocations,
      component: lazy(() => import('src/views/ManageLocation'))
    },
    {
      exact: true,
      path: PATH_APP.report.editLocations,
      component: lazy(() => import('src/views/ManageLocation/editCar'))
    },
    {
      exact: true,
      path: PATH_APP.report.AddLocations,
      component: lazy(() => import('src/views/ManageLocation/addCars'))
    },
    {
      exact: true,
      path: PATH_APP.report.LocationQueueDriverLogs,
      component: lazy(() => import('src/views/LocationQueueDriverLogs'))
    },
    {
      exact: true,
      path: PATH_APP.report.LocationEntryExitLogs,
      component: lazy(() =>
        import('src/views/LocationQueueDriverLogs/LocationEntryExitLogs')
      )
    },

    // Tolls
    {
      exact: true,
      path: PATH_APP.report.manageTolls,
      component: lazy(() => import('src/views/ManageTolls'))
    },
    {
      exact: true,
      path: PATH_APP.report.addEditTolls,
      component: lazy(() => import('src/views/ManageTolls/addEditTolls'))
    },
    // taxiSubscriptionList
    {
      exact: true,
      path: PATH_APP.report.taxiList,
      component: lazy(() => import('src/views/Taxi Subscription List'))
    },
    {
      exact: true,
      path: PATH_APP.report.taxiCompletedList,
      component: lazy(() =>
        import('src/views/Taxi Subscription List/tableCompleteTrip')
      )
    },
    // AllTransaction
    {
      exact: true,
      path: PATH_APP.report.allTransactions,
      component: lazy(() => import('src/views/AllTransaction'))
    },
    // AccounteReport
    {
      exact: true,
      path: PATH_APP.report.accountReports,
      component: lazy(() => import('src/views/AccountReports'))
    },
    {
      exact: true,
      path: PATH_APP.report.cancelledLinkPage,
      component: lazy(() => import('src/views/CancelledTransactionList'))
    },
    {
      exact: true,
      path: PATH_APP.report.profileLink,
      component: lazy(() => import('src/views/ProfileLink'))
    },
    {
      exact: true,
      path: PATH_APP.report.completedLink,
      component: lazy(() => import('src/views/CompletedTransactionList'))
    },
    {
      exact: true,
      path: PATH_APP.report.CompletedTransactions,
      component: lazy(() => import('src/views/CompletedTransaction'))
    },
    {
      exact: true,
      path: PATH_APP.report.cancelledTransactions,
      component: lazy(() => import('src/views/CancelledTransactions'))
    },
    {
      exact: true,
      path: PATH_APP.report.supervisorTripReports,
      component: lazy(() => import('src/views/SupervisorReport'))
    },
    {
      exact: true,
      path: PATH_APP.report.historicalData,
      component: lazy(() => import('src/views/HistoricalReports'))
    },
    {
      exact: true,
      path: PATH_APP.report.companyLocationsEdit,
      component: lazy(() => import('src/views/CompaniesLocation/editLocation'))
    },
    {
      exact: true,
      path: PATH_APP.report.companyLocations,
      component: lazy(() => import('src/views/CompaniesLocation'))
    },
    {
      exact: true,
      path: PATH_APP.report.companyLocationsAdd,
      component: lazy(() => import('src/views/CompaniesLocation/addLocation'))
    },
    {
      exact: true,
      path: PATH_APP.report.driverListAddEdit,
      component: lazy(() =>
        import('src/views/CompaniesLocation/driverListAddEdit')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.multipleDropOff,
      component: lazy(() =>
        import('src/views/CompaniesLocation/multipleDropOffAddEdit')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.LocationCustomizedFare,
      component: lazy(() =>
        import('src/views/CompaniesLocation/LocationCustomizedFare')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageLocationQueue,
      component: lazy(() =>
        import('src/views/CompaniesLocation/manageLocationQueue')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.locationQueue,
      component: lazy(() =>
        import('src/views/CompaniesLocation/locationQueue')
      )
    },

    {
      exact: true,
      path: PATH_APP.report.profile,
      component: lazy(() => import('src/views/profile'))
    },
    {
      exact: true,
      path: PATH_APP.report.companies,
      component: lazy(() => import('src/views/ManageCompanies'))
    },
    {
      exact: true,
      path: PATH_APP.report.companiesAdd,
      component: lazy(() => import('src/views/ManageCompanies/addCars'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageCompanyAddCars,
      component: lazy(() => import('src/views/ManageCars/addCars'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageDriverCompanyAdd,
      // component: lazy(() => import('src/views/app/ChatView/index')),
      component: lazy(() => import('src/views/ManageDriver/Add'))
    },

    {
      exact: true,
      path: PATH_APP.report.companiesEdit,
      component: lazy(() => import('src/views/ManageCompanies/editCar'))
    },

    // Manage Promocode Start
    {
      exact: true,
      path: PATH_APP.report.managePromocode,
      component: lazy(() => import('src/views/ManagePromocode'))
    },
    {
      exact: true,
      path: PATH_APP.report.addPromocode,
      component: lazy(() => import('src/views/ManagePromocode/AddPromocode'))
    },
    {
      exact: true,
      path: PATH_APP.report.editPromocode,
      component: lazy(() => import('src/views/ManagePromocode/EditPromocode'))
    },

    // Manage Promocode End

    // priyanka starts
    //manage model start 
    {
      exact: true,
      path: PATH_APP.report.manageModel,
      component: lazy(() => import('src/views/ManageModelNew'))
    },
    {
      exact: true,
      path: PATH_APP.report.addModel,
      component: lazy(() => import('src/views/ManageModelNew/addEdit'))
    },
    //manage model end 

    // priyanka ends

    // Custom zone start
    {
      exact: true,
      path: PATH_APP.report.manageCustomZone,
      component: lazy(() => import('src/views/manageCustomZone'))
    },
    {
      exact: true,
      path: PATH_APP.report.addCustomzone,
      component: lazy(() =>
        import('src/views/manageCustomZone/AddEditCustomZone')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.customzoneFare,
      component: lazy(() => import('src/views/manageCustomZone/CustomzoneFare'))
    },
    {
      exact: true,
      path: PATH_APP.report.customzoneNotification,
      component: lazy(() =>
        import('src/views/manageCustomZone/customZoneNotification')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.customDriverListAddEdit,
      component: lazy(() =>
        import('src/views/manageCustomZone/DriverListAddEdit')
      )
    },
    // Custom zone End

    // Fine Start
    {
      exact: true,
      path: PATH_APP.report.manageFine,
      component: lazy(() => import('src/views/ManageFine'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageFineCategory,
      component: lazy(() => import('src/views/ManageFineCategory'))
    },
    {
      exact: true,
      path: PATH_APP.report.addFine,
      component: lazy(() => import('src/views/ManageFine/addFine'))
    },

    // Corporate Start
    {
      exact: true,
      path: PATH_APP.report.corporateAdd,
      component: lazy(() => import('src/views/ManageCorporate/addCorporate'))
    },
    {
      exact: true,
      path: PATH_APP.report.corporateEdit,
      component: lazy(() => import('src/views/ManageCorporate/editCorporate'))
    },
    {
      exact: true,
      path: PATH_APP.report.zoneFareMapping,
      component: lazy(() => import('src/views/ManageCorporate/zoneFareMapping'))
    },
    {
      exact: true,
      path: PATH_APP.report.zoneFareMappingWithModel,
      component: lazy(() =>
        import('src/views/ManageCorporate/ZoneFareMappingWithModel')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.corporates,
      component: lazy(() => import('src/views/ManageCorporate'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageZone,
      component: lazy(() => import('src/views/ManageCorporate/manageZone'))
    },
    {
      exact: true,
      path: PATH_APP.report.addManageZone,
      component: lazy(() => import('src/views/ManageCorporate/AddZone'))
    },
    {
      exact: true,
      path: PATH_APP.report.zoneGroup,
      component: lazy(() => import('src/views/ManageCorporate/zoneGroup'))
    },
    {
      exact: true,
      path: PATH_APP.report.zoneGroupAdd,
      component: lazy(() => import('src/views/ManageCorporate/zoneGroupAdd'))
    },
    {
      exact: true,
      path: PATH_APP.report.zoneGroupEdit,
      component: lazy(() => import('src/views/ManageCorporate/zoneGroupEdit'))
    },

    // Santhosh start
    {
      exact: true,
      path: PATH_APP.report.manageManufacturer,
      component: lazy(() => import('src/views/ManageManufacturer'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageManufacturerAdd,
      component: lazy(() =>
        import('src/views/ManageManufacturer/AddEditManufacturer')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageCarMake,
      component: lazy(() => import('src/views/ManageCarMaker'))
    },
    {
      exact: true,
      path: PATH_APP.report.manageCarMakeAdd,
      component: lazy(() => import('src/views/ManageCarMaker/AddEditCarMake'))
    },
    // Santhosh End
    {
      exact: true,
      path: PATH_APP.report.corporatePackage,
      component: lazy(() => import('src/views/CorporatePackage'))
    },
    {
      exact: true,
      path: PATH_APP.report.addPackage,
      component: lazy(() => import('src/views/CorporatePackage/AddPackage'))
    },
    {
      exact: true,
      path: PATH_APP.report.editPackage,
      component: lazy(() => import('src/views/CorporatePackage/EditPackage'))
    },
    // Corporate End
    {
      exact: true,
      path: PATH_APP.report.manageFare,
      component: lazy(() => import('src/views/FareManagement'))
    },
    {
      exact: true,
      path: PATH_APP.report.modelInfo,
      component: lazy(() => import('src/views/FareManagement/ModelInfo'))
    },
    // {
    //   exact: true,
    //   path: PATH_APP.report.addFare,
    //   component: lazy(() => import('src/views/FareManagement/AddFare'))
    // },

    // Passenger start
    {
      exact: true,
      path: PATH_APP.report.passengers,
      component: lazy(() => import('src/views/ManagePassengers'))
    },
    {
      exact: true,
      path: PATH_APP.report.passengerInfo,
      component: lazy(() => import('src/views/ManagePassengers/PassengerInfo'))
    },
    {
      exact: true,
      path: PATH_APP.report.addEditPassenger,
      component: lazy(() =>
        import('src/views/ManagePassengers/AddEditPassenger')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.passengerPackage,
      component: lazy(() => import('src/views/PassengerPackage'))
    },
    {
      exact: true,
      path: PATH_APP.report.addEditPackage,
      component: lazy(() => import('src/views/PassengerPackage/AddEditPackage'))
    },
    // Passenger End

    // SMS Template Start
    {
      exact: true,
      path: PATH_APP.report.manageSmsTemplates,
      component: lazy(() => import('src/views/ManageSmsTemplates'))
    },
    {
      exact: true,
      path: PATH_APP.report.addEditSmsTemplate,
      component: lazy(() =>
        import('src/views/ManageSmsTemplates/AddEditSMSTemplate.js')
      )
    },
    // SMS Template End
    
    // {
    //   exact: true,
    //   path: PATH_APP.report.manageAdminSmtpMailSetting,
    //   component: lazy(() =>
    //     import('src/views/ManageAdminSmtpSettings')
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.report.manageSmtpMailSetting,
      component: lazy(() =>
        import('src/views/ManageSmtpSettings')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.editAdminSMTPSetting,
      component: lazy(() =>
        import('src/views/ManageAdminSmtpSettings/editAdminSMTPSetting')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.addEditMailSetting,
      component: lazy(() =>
        import('src/views/ManageSmtpSettings/AddEditSMTPSetting')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.editSMSSMTPSetting,
      component: lazy(() =>
        import('src/views/ManageAdminSmsSettings/editAdminSmsSetting')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageSMSSetting,
      component: lazy(() =>
        import('src/views/ManageSmsSettings')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.addEditSMSSetting,
      component: lazy(() =>
        import('src/views/ManageSmsSettings/AddEditSmsSetting')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageCountry,
      component: lazy(() =>
        import('src/views/ManageCountry')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.addEditManageCountry,
      component: lazy(() =>
        import('src/views/ManageCountry/AddEditCountry')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageState,
      component: lazy(() =>
        import('src/views/ManageState')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.addEditManageState,
      component: lazy(() =>
        import('src/views/ManageState/AddEditState')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageCity,
      component: lazy(() =>
        import('src/views/ManageCity')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.addEditManageCity,
      component: lazy(() =>
        import('src/views/ManageCity/AddEditCity')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.addEditSocialNetwork,
      component: lazy(() =>
        import('src/views/ManageSocialNetwork/AddEditSocialNetwork')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageSiteSettings,
      component: lazy(() =>
        import('src/views/ManageSiteSettings/manageSiteSetting')
      )
    },
    {
      exact: true,
      path: PATH_APP.report.manageGoogleSettings,
      component: lazy(() =>
        import('src/views/ManageGoogleSettings')
      )
    },
    
    {
      exact: true,
      path: PATH_APP.report.addEditFare,
      component: lazy(() => import('src/views/FareManagement/AddEditFare'))
    },
    {
      exact: true,
      path: PATH_APP.report.supervisorLogs,
      component: lazy(() => import('src/views/SupervisorLogs'))
    },
    {
      exact: true,
      path: PATH_APP.report.activityLogs,
      component: lazy(() => import('src/views/ActivityLogs'))
    },
    {
      exact: true,
      path: PATH_APP.report.locationEditLogs,
      component: lazy(() => import('src/views/ActivityLogs/LocationEditLogs'))
    },
    {
      exact: true,
      path: PATH_APP.report.transactionlist,
      component: lazy(() => import('src/views/ActivityLogs/TransactionList'))
    },

    {
      exact: true,
      path: PATH_APP.report.manageCars,
      component: lazy(() => import('src/views/ManageCars'))
    },
    {
      exact: true,
      path: PATH_APP.report.AddCars,
      component: lazy(() => import('src/views/ManageCars/addCars'))
    },
    {
      exact: true,
      path: PATH_APP.report.carInfo,
      component: lazy(() => import('src/views/ManageCars/carInfo'))
    },
    {
      exact: true,
      path: PATH_APP.report.assignCars,
      component: lazy(() => import('src/views/ManageAssignedCars'))
    },
    {
      exact: true,
      path: PATH_APP.report.assignCar,
      component: lazy(() => import('src/views/ManageCars/AssignCar'))
    },
    {
      exact: true,
      path: PATH_APP.report.rejectedTrips,
      component: lazy(() => import('src/views/RejectedTrips'))
    },
    {
      exact: true,
      path: PATH_APP.report.pendingPaymentDetails,
      component: lazy(() => import('src/views/PendingPaymentDetails'))
    },
    {
      exact: true,
      path: PATH_APP.report.companiesInfo,
      component: lazy(() => import('src/views/ManageCompanies/CompanyInfo'))
    },

    {
      exact: true,
      path: PATH_APP.report.cancelTripReport,
      component: lazy(() => import('src/views/ManageCancelTripFares'))
    },
    {
      exact: true,
      path: PATH_APP.report.driverShiftHistory,
      component: lazy(() => import('src/views/DriverShiftHistory'))
    },
    {
      exact: true,
      path: PATH_APP.report.zoneEntryLogs,
      component: lazy(() => import('src/views/ZoneEntryLogs'))
    },
    {
      exact: true,
      path: PATH_APP.report.addAssignCars,
      component: lazy(() => import('src/views/ManageAssignedCars/Add'))
    },
    {
      exact: true,
      path: PATH_APP.report.editAssignedCars,
      component: lazy(() => import('src/views/ManageAssignedCars/Edit'))
    },

    // jackson end

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to={endPath} />
    }
    // {
    //   component: () => <Redirect to="/404" />
    // }
  ]
};

// Page Route Validation start

// const validRoutes = ValidRoutes(AppRoutes);

// console.log("validRoutes:", validRoutes)

export default AppRoutes;
