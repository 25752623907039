/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { TextField, Grid, Card, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    width: '400px'
  },
  trending: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  }
}));

export default function NewDateRange(props) {
  const classes = useStyles();
  const [value, setValue] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year')
    ]
  };

  const handleEvent = (event, picker) => {
    props.func(picker.startDate._d, picker.endDate._d);
    // console.log('start: ', picker.startDate._d);
    // console.log('end: ', picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
    setValue(fromDate);

    // console.log('yesteday')
    // setValue(fromDate);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <DateRangePicker
        // startDate={new Date()}
        //   endDate={new Date()}
        ranges={range}
        label="Show report for"
        // alwaysShowCalendars={true}
        onApply={handleEvent}
        singleDatePicker={false}
        maxDate={moment()}
        style={{ width: '100%' }}
      >
        <TextField
          fullWidth
          margin="normal"
          value={`${moment(fromDate).format('LL')} to ${moment(toDate).format(
            'LL'
          )}`}
          style={{ width: '100%' }}
        >
          {moment(fromDate).format('LL')} to {moment(toDate).format('LL')}
        </TextField>
      </DateRangePicker>
    </Box>
  );
}
