/* eslint-disable prettier/prettier */
import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP, PATH_APP_WEBBOOK, PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;
// const path1 = (name) => `/static/icons/navbar/png/${name}.png`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  locationLog: <MIcon src={path('Artboard 2')} />,
  locationGroup: <MIcon src={path('Artboard 4')} />,
  order: <MIcon src={path('Artboard 6')} />,
  settings: <MIcon src={path('Artboard 9')} />,
  report: <MIcon src={path('Artboard 3')} />,
  rider: <MIcon src={path('Artboard 5')} />,
  superVisor: <MIcon src={path('Artboard 10')} />,
  driverManagement: <MIcon src={path('name-id-icon')} />,
  carDrivers: <MIcon src={path('driver-icon')} />,
  taxiIcon: <MIcon src={path('taxi-icon')} />,
  liveTracking: <MIcon src={path('liveTracking')} />,
  corporate: <MIcon src={path('corporate')} />,
  toll: <MIcon src={path('toll')} />,
  setting: <MIcon src={path('setting-icon')} />,
  location: <MIcon src={path('location')} />,
  discount: <MIcon src={path('discount-icon')} />,
  customer: <MIcon src={path('customer')} />,
  companies: <MIcon src={path('companies')} />,
  cars: <MIcon src={path('cars-icon')} />
};

const navConfig = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        href: PATH_APP_WEBBOOK.report.webBooking,
        icon: ICONS.dashboard
      }
    ]
  }
];

export default navConfig;
