/* eslint-disable prettier/prettier */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
// import Search from './Search';
import Account from './Account';
import PropTypes from 'prop-types';
// import Languages from './Languages';
import { Icon } from '@iconify/react';
import NotificationSound from './NotificationSound';
import Notifications from './Notifications';
import NotificationsB2B from './NotificationsB2B';
import NotificationDojoin from './NotificationDojoin';
import Settings from 'src/layouts/Common/Settings';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardNotification } from 'src/redux/slices/notifications';
import Search from './Search';
import { getCompanyId } from 'src/redux/slices/authJwt';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backdropFilter: 'blur(8px)',
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('xl')]: {
      paddingLeft: DRAWER_WIDTH
    }
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5)
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP
    }
  }
}));

// ----------------------------------------------------------------------

TopBar.propTypes = {
  onOpenNav: PropTypes.func,
  className: PropTypes.string
};

function TopBar({ onOpenNav, className }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyIdFromLocalStorage = Number(getCompanyId());
  const userData = JSON.parse(localStorage.getItem('userData'));

  const { dashboardNotificationData } = useSelector(
    (state) => state.notifications
  );

  const [notificationData, setNotificationData] = useState(null);
  const [loader, setLoader] = useState(false);

  const [totalB2bReadStatusOne, setTotalB2bReadStatusOne] = useState(0);
  const [totalB2cReadStatusOne, setTotalB2cReadStatusOne] = useState(0);
  const [totalDojoinReadStatusOne, setTotalDojoinReadStatusOne] = useState(0);
  const [totalB2bUnread, setTotalB2bUnread] = useState(0);
  const [totalB2cUnread, setTotalB2cUnread] = useState(0);
  const [totalDojoinUnread, setTotalDojoinUnread] = useState(0);

  const [playSound, setPlaySound] = useState(false);

  const [playSoundB2b, setPlaySoundB2b] = useState(false);
  const [playSoundB2c, setPlaySoundB2c] = useState(false);
  const [playSoundDojoin, setPlaySoundDojoin] = useState(false);

  // ⚠️ Need to unComment ⚠️

  useEffect(() => {
    // console.log('loader:', loader);
    const userData = JSON.parse(localStorage.getItem('userData'));
    setLoader(true);
    setPlaySound(true);
    setPlaySoundB2b(true);
    setPlaySoundB2c(true);
    setPlaySoundDojoin(true);
    const intervalId = setInterval(() => {
      if (userData.userType === 'A') {
        dispatch(
          dashboardNotification({ companyId: companyIdFromLocalStorage })
        );
      }
      // console.log('Notification Call:');
    }, 10000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (dashboardNotificationData) {
      // console.log('dashboardNotificationData:', dashboardNotificationData);
      setNotificationData(dashboardNotificationData.responseData);
      // console.log('myData:', dashboardNotificationData.responseData);

      // B2b
      const b2bSection = dashboardNotificationData.responseData?.b2b || {};
      let calculatedTotalB2bReadStatusOne = 0;
      let visitedTripIdsb2b = new Set();

      // Iterate through the nested objects to calculate total read status
      Object.values(b2bSection).forEach((nestedObject) => {
        nestedObject.ids.forEach((item) => {
          if (item.readStatus === 0 && !visitedTripIdsb2b.has(item.tripId)) {
            calculatedTotalB2bReadStatusOne++;
            visitedTripIdsb2b.add(item.tripId);
          }
        });
      });

      setTotalB2bReadStatusOne(calculatedTotalB2bReadStatusOne);

      // B2c
      const b2cSection = dashboardNotificationData.responseData?.b2c || {};
      let calculatedTotalB2cReadStatusOne = 0;
      let visitedTripIdsb2c = new Set();

      // Iterate through the nested objects to calculate total read status
      Object.values(b2cSection).forEach((nestedObject) => {
        nestedObject.ids.forEach((item) => {
          if (item.readStatus === 0 && !visitedTripIdsb2c.has(item.tripId)) {
            calculatedTotalB2cReadStatusOne++;
            visitedTripIdsb2c.add(item.tripId);
          }
        });
      });

      setTotalB2cReadStatusOne(calculatedTotalB2cReadStatusOne);
      // console.log(
      //   'calculatedTotalB2cReadStatusOne:',
      //   calculatedTotalB2cReadStatusOne
      // );

      const dojoinSection =
        dashboardNotificationData.responseData?.doJoin || {};
      let calculatedTotalDojoinReadStatusOne = 0;
      let visitedTripIdsDojoin = new Set();

      // Iterate through the nested objects to calculate total read status
      Object.values(dojoinSection).forEach((nestedObject) => {
        nestedObject.ids.forEach((item) => {
          if (item.readStatus === 0 && !visitedTripIdsDojoin.has(item.tripId)) {
            calculatedTotalDojoinReadStatusOne++;
            visitedTripIdsDojoin.add(item.tripId);
          }
        });
      });

      setTotalDojoinReadStatusOne(calculatedTotalDojoinReadStatusOne);
    }
  }, [dashboardNotificationData]);

  useEffect(() => {
    // console.log('console 1.1:');
    if (loader) {
      // console.log('loader:', loader);
      if (dashboardNotificationData) {
        // B2b
        const b2bSection = dashboardNotificationData.responseData?.b2b || {};
        let calculatedTotalB2bReadStatusOne = 0;
        let visitedTripIdsb2b = new Set();
        // Iterate through the nested objects to calculate total read status
        Object.values(b2bSection).forEach((nestedObject) => {
          nestedObject.ids.forEach((item) => {
            if (item.readStatus === 0 && !visitedTripIdsb2b.has(item.tripId)) {
              calculatedTotalB2bReadStatusOne++;
              visitedTripIdsb2b.add(item.tripId);
            }
          });
        });
        // console.log(
        //   'calculatedTotalB2bReadStatusOne: 2',
        //   calculatedTotalB2bReadStatusOne
        // );
        setTotalB2bUnread(calculatedTotalB2bReadStatusOne);
        // B2c
        const b2cSection = dashboardNotificationData.responseData?.b2c || {};
        let calculatedTotalB2cReadStatusOne = 0;
        let visitedTripIdsb2c = new Set();

        // Iterate through the nested objects to calculate total read status
        Object.values(b2cSection).forEach((nestedObject) => {
          nestedObject.ids.forEach((item) => {
            if (item.readStatus === 0 && !visitedTripIdsb2c.has(item.tripId)) {
              calculatedTotalB2cReadStatusOne++;
              visitedTripIdsb2c.add(item.tripId);
            }
          });
        });

        setTotalB2cUnread(calculatedTotalB2cReadStatusOne);

        // Dojoin
        const doJoinSection =
          dashboardNotificationData.responseData?.doJoin || {};
        let calculatedTotaldoJoinReadStatusOne = 0;
        let visitedTripIdsDoJoin = new Set();

        // Iterate through the nested objects to calculate total read status
        Object.values(doJoinSection).forEach((nestedObject) => {
          nestedObject.ids.forEach((item) => {
            if (
              item.readStatus === 0 &&
              !visitedTripIdsDoJoin.has(item.tripId)
            ) {
              calculatedTotaldoJoinReadStatusOne++;
              visitedTripIdsDoJoin.add(item.tripId);
            }
          });
        });

        setTotalDojoinUnread(calculatedTotaldoJoinReadStatusOne);

        setLoader(false);
      }
    }
  }, [dashboardNotificationData]);

  // For B2b Sound

  useEffect(() => {
    // console.log('comes 1.1', totalB2bReadStatusOne, totalB2bUnread);
    // console.log('calculatedTotalB2cReadStatusOne: 3');
    if (totalB2bReadStatusOne > totalB2bUnread) {
      // console.log('Comes 1.2');
      setTotalB2bUnread(totalB2bReadStatusOne);
      setPlaySound(true);
      setPlaySoundB2b(true);
      // console.log('calculatedTotalB2cReadStatusOne: 4');
    } else if (totalB2bReadStatusOne < totalB2bUnread) {
      // console.log('comes 1.3');
      setTotalB2bUnread(0);
      setTotalB2bReadStatusOne(0);
      setLoader(true);
    }
  }, [totalB2bReadStatusOne]);

  // For B2c Sound

  useEffect(() => {
    // console.log('calculatedTotalB2cReadStatusOne: 4');
    if (totalB2cReadStatusOne > totalB2cUnread) {
      setTotalB2cUnread(totalB2cReadStatusOne);
      setPlaySound(true);
      setPlaySoundB2c(true);
      // console.log('totalUnread: 1');
      // console.log('calculatedTotalB2cReadStatusOne: 4');
    } else if (totalB2cReadStatusOne < totalB2cUnread) {
      setTotalB2cUnread(0);
      setTotalB2cReadStatusOne(0);
      setLoader(true);
    }
  }, [totalB2cReadStatusOne]);

  // For Dojoin sound
  useEffect(() => {
    if (companyIdFromLocalStorage === 0) {
      if (totalDojoinReadStatusOne > totalDojoinUnread) {
        setTotalDojoinUnread(totalDojoinReadStatusOne);
        setPlaySound(true);
        setPlaySoundDojoin(true);
      } else if (totalDojoinReadStatusOne < totalDojoinUnread) {
        setTotalDojoinUnread(0);
        setTotalDojoinReadStatusOne(0);
        setLoader(true);
      }
    }
  }, [totalB2cReadStatusOne]);

  // console.log('totalUnread: 2', totalB2bUnread);
  // console.log('totalUnread: 2.1', totalB2bReadStatusOne);
  // console.log('totalUnread: 3', playSoundB2b, playSoundB2c, playSound);

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <Hidden xlUp>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary'
            }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        {/* <Search /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3
              }
            }
          }}
        >
          {/* Check committ */}
          {/* <Languages /> */}

          {/* Jackson Start */}

          {userData.userType === 'A' && (
            <>
              {/* <Notifications  /> */}
              <NotificationSound
                playSound={playSound}
                setPlaySound={setPlaySound}
              />
              {/* B2B */}
              <NotificationsB2B
                notificationDatas={notificationData}
                playSound={playSoundB2b}
                setPlaySoundB2b={setPlaySoundB2b}
              />

              {/* B2C */}
              <Notifications
                notificationDatas={notificationData}
                playSound={playSoundB2c}
                setPlaySoundB2c={setPlaySoundB2c}
              />
            </>
          )}

          {/* DoJoin */}

          {companyIdFromLocalStorage === 0 && userData.userType === 'A' && (
            <NotificationDojoin
              notificationDatas={notificationData}
              playSound={playSoundDojoin}
              setPlaySoundDojoin={setPlaySoundDojoin}
            />
          )}

          {/* Jackson End */}
          <Settings />
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
