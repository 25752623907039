/* eslint-disable prettier/prettier */
import NProgress from 'nprogress';

import AppRoutes from './AppRoutes';
import { PATH_APP, PATH_PAGE } from './paths';
import HomeRoutes from './HomeRoutes';
import DocsRoutes from './DocsRoutes';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestProtect from 'src/components/Auth/GuestProtect';
import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CorpAppRoutes from './CorporateRoutes';
import WebBookerRoutes from './WebBookerRoutes';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  // console.log("MenuVals:",JSON.parse(localStorage.getItem('menuAccess')));

  const menuAccessString = localStorage.getItem('menuAccess');
  const allowedRoutes = menuAccessString ? JSON.parse(menuAccessString) : [];

  function collectHrefs(data) {
    const hrefs = [];
    function recurse(items) {
      for (const item of items) {
        if (item.href) {
          hrefs.push(item.href);
        }
        if (item.items) {
          recurse(item.items);
        }
      }
    }
    recurse(data);
    return hrefs;
  }
  
  const allHrefs = collectHrefs(allowedRoutes);
  
  const allowedPaths = allHrefs
    .map((key) => {
      const keys = key.split('.');
      if (keys[0] === 'report' && keys[1] in PATH_APP.report) {
        return PATH_APP.report[keys[1]];
      }
      return null;
    })
    .filter(Boolean);

    // console.log("allowedPaths", allowedPaths)
  
  // // Filtering AppRoutes based on allowed paths
  const filteredRoutes = AppRoutes.routes.filter((route) =>
    allowedPaths.includes(route.path)
  );

  // console.log("filteredRoutes", filteredRoutes)
  
  // const unmatchedPaths = AppRoutes.routes
  //   .filter((route) => !filteredRoutes.includes(route.path))
  //   .map((route) => route.path);
  
  // console.log('unmatchedPaths', unmatchedPaths);
  // console.log('filteredRoutes', filteredRoutes);
  // console.log('routes', routes);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.login,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.login2,
    component: lazy(() => import('src/views/auth/LoginViewManual'))
  },
  {
    
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.corporateLogin,
    component: lazy(() => import('src/views/auth/LoginViewCorporate'))
  },
  {
    
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.webBookerLogin,
    component: lazy(() => import('src/views/auth/LoginViewWebbooker'))
  },
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.logindirect,
    component: lazy(() => import('src/views/auth/LoginView2'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.loginUnprotected,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.register,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.registerUnprotected,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.resetPassword,
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.resetCorporatePassword,
    component: lazy(() => import('src/views/auth/ResetPasswordCorporateView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.verify,
    component: lazy(() => import('src/views/auth/VerifyCodeView'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/Page404View'))
  },
  {
    exact: true,
    path: '/500',
    component: lazy(() => import('src/views/errors/Page500View'))
  },
  {
    exact: true,
    path: PATH_PAGE.comingSoon,
    component: lazy(() => import('src/views/pages/ComingSoonView'))
  },
  {
    exact: true,
    path: PATH_PAGE.maintenance,
    component: lazy(() => import('src/views/pages/MaintenanceView'))
  },
  {
    exact: true,
    path: PATH_PAGE.pricing,
    component: lazy(() => import('src/views/pages/PricingView'))
  },
  {
    exact: true,
    path: PATH_PAGE.payment,
    component: lazy(() => import('src/views/pages/PaymentView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />
  },

  // App Routes
  AppRoutes,

  // Docs Routes
  DocsRoutes,
  
  // Corporate Routes
  CorpAppRoutes,

  // Home Routes
  HomeRoutes,

  // Webbooker
  WebBookerRoutes
];

export default routes;
