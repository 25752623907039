/* eslint-disable prettier/prettier */
// Navconfig Start
import { MIcon } from 'src/theme';
import {
  PATH_APP,
  PATH_PAGE,
  PATH_APP_CORP,
  PATH_APP_WEBBOOK
} from 'src/routes/paths';
// Navconfig End

import NavItem from './NavItem';
import MenuLinks from './configMain';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
// import useAuth from 'src/hooks/useAuth';
import React, { useEffect } from 'react';
// import MyAvatar from 'src/components/MyAvatar';
import Scrollbars from 'src/components/Scrollbars';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, List, Drawer, Hidden, ListSubheader } from '@material-ui/core';
import navConfig from './configUser';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    drawer: {
      [theme.breakpoints.up('xl')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
      }
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary
    },
    account: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800]
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter
    }
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </NavItem>
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
      />
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool
};

function NavBar({ isOpenNav, onCloseNav }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  // const { user } = useAuth();
  const path = (name) => `/static/icons/navbar/${name}.svg`;
  const ICONS = {
    authenticator: <MIcon src={path('ic_authenticator')} />,
    blog: <MIcon src={path('ic_blog')} />,
    calendar: <MIcon src={path('ic_calendar')} />,
    cart: <MIcon src={path('ic_cart')} />,
    charts: <MIcon src={path('ic_charts')} />,
    chat: <MIcon src={path('ic_chat')} />,
    components: <MIcon src={path('ic_components')} />,
    dashboard: <MIcon src={path('ic_dashboard')} />,
    editor: <MIcon src={path('ic_editor')} />,
    elements: <MIcon src={path('ic_elements')} />,
    error: <MIcon src={path('ic_error')} />,
    mail: <MIcon src={path('ic_mail')} />,
    map: <MIcon src={path('ic_map')} />,
    page: <MIcon src={path('ic_page')} />,
    user: <MIcon src={path('ic_user')} />,
    toll: <MIcon src={path('toll')} />,
    upload: <MIcon src={path('ic_upload')} />,
    copy: <MIcon src={path('ic_copy')} />,
    carousel: <MIcon src={path('ic_carousel')} />,
    language: <MIcon src={path('ic_language')} />,
    locationLog: <MIcon src={path('Artboard 2')} />,
    locationGroup: <MIcon src={path('Artboard 4')} />,
    order: <MIcon src={path('Artboard 6')} />,
    settings: <MIcon src={path('Artboard 9')} />,
    report: <MIcon src={path('Artboard 3')} />,
    rider: <MIcon src={path('Artboard 5')} />,
    superVisor: <MIcon src={path('Artboard 10')} />,
    driverManagement: <MIcon src={path('name-id-icon')} />,
    carDrivers: <MIcon src={path('driver-icon')} />,
    taxiIcon: <MIcon src={path('taxi-icon')} />,
    liveTracking: <MIcon src={path('liveTracking')} />,
    corporate: <MIcon src={path('corporate')} />,
    setting: <MIcon src={path('setting-icon')} />,
    location: <MIcon src={path('location')} />,
    discount: <MIcon src={path('discount-icon')} />,
    customer: <MIcon src={path('customer')} />,
    companies: <MIcon src={path('companies')} />,
    cars: <MIcon src={path('cars-icon')} />
  };

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const companyId = localStorage.getItem('companyId');
  const userId = localStorage.getItem('userId');
  const userData = JSON.parse(localStorage.getItem('userData'));

  // Navconfig start
  let navConfigData = JSON.parse(localStorage.getItem('menuAccess'));
  // console.log('navConfigData Index:', navConfigData);

  const pathAPP =
    userData.userType === 'CP'
      ? PATH_APP_CORP
      : userData.userType === 'WB'
      ? PATH_APP_WEBBOOK
      : PATH_APP;

  function processApiResponse(response) {
    if (!response) return [];

    return response
      .map((section) => {
        let newSection = { ...section };

        if (section.items) {
          // Filter items by status and process them
          newSection.items = section.items
            .filter((item) => item.status === 1) // Filter out items with status 0
            .map((item) => processItem(item));
        }

        return newSection;
      })
      .filter((section) => section.items && section.items.length > 0); // Remove sections without valid items
  }

  function processItem(item) {
    let transformedItem = { ...item };

    // If this item contains nested items and they are not null, process them recursively
    if (item.items && Array.isArray(item.items)) {
      // Filter nested items by status and process them
      transformedItem.items = item.items
        .filter((subItem) => subItem.status === 1)
        .map((subItem) => processItem(subItem));

      // Remove href for parent items with submenus
      // delete transformedItem.href;
    } else {
      transformedItem.href = getHrefFromKey(item.href);
    }

    if (item.icon) {
      transformedItem.icon = ICONS[item.icon];
    }

    return transformedItem;
  }

  function getHrefFromKey(key) {
    if (!key) return ''; // In case key is null or undefined

    const keys = key.split('.');
    if (keys.length === 1) {
      return PATH_PAGE[keys[0]];
    } else if (keys.length === 2) {
      return pathAPP[keys[0]][keys[1]];
    }
    // handle more nested paths or return a default/fallback if necessary
  }

  const processedNavConfig = processApiResponse(navConfigData);

  // console.log('processedNavConfig:', processedNavConfig);

  // Navconfig End

  // Live Working !!

  // MenuLinks -- > Super Admin
  // processedNavConfig -- > Admin User && Company & corporate login & !webbooker login

  const menuToRender =
    userId === '1' && userData.userType === 'A'
      ? MenuLinks
      : processedNavConfig;

  // For Testing !! use this for testing ⚠️
  // const menuToRender = navConfig;

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      {/* <Link
        underline="none"
        component={RouterLink}
        to={PATH_APP.management.user.account}
      >
        <div className={classes.account}>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.role}
            </Typography>
          </Box>
        </div>
      </Link> */}

      {/* {MenuLinks.map((list) => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              className={classes.subHeader}
            >
              {list.subheader}
            </ListSubheader>
          }
        >
          {renderNavItems({
            items: list.items,
            pathname: pathname
          })}
        </List>
      ))} */}

      {menuToRender.map((list) => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              className={classes.subHeader}
            >
              {list.subheader}
            </ListSubheader>
          }
        >
          {renderNavItems({
            items: list.items,
            pathname: pathname
          })}
        </List>
      ))}

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <div className={classes.doc}>
          <Box
            component="img"
            alt="doc"
            src="/static/icons/ic_doc.svg"
            sx={{ width: 36, height: 36, mb: 2 }}
          />
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{ color: 'grey.800' }}
          >
            Hi, {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>

          <Button
            fullWidth
            to={PATH_DOCS.root}
            variant="contained"
            component={RouterLink}
          >
            Documentation
          </Button>
        </div>
      </Box> */}
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden xlUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;
