/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import { useEffect } from 'react';
import { getUserId } from 'src/redux/slices/authJwt';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    const userIdlocal = Number(localStorage.getItem('userId'));
    // console.log("getUserId Axios", userIdlocal);
    const commonQueryParams = {
       userId: userIdlocal
    };

    config.params = {
        ...config.params,
        ...commonQueryParams,
    };

    return config;
});

export default axiosInstance;
