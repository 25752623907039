/* eslint-disable prettier/prettier */
import clsx from 'clsx';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { PATH_APP, PATH_APP_CORP } from 'src/routes/paths';
import MyAvatar from 'src/components/MyAvatar';
import React, { useRef, useState } from 'react';
import homeFill from '@iconify-icons/eva/home-fill';
import swapFill from '@iconify-icons/eva/swap-fill';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PopoverMenu from 'src/components/PopoverMenu';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import personFill from '@iconify-icons/eva/person-fill';
import settings2Fill from '@iconify-icons/eva/settings-2-fill';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
import { MIconButton } from 'src/theme';
import { RSLUrl } from 'src/config';
import {
  getCompanyInfo,
  getCompanyLoader,
  getThemeInfo
} from 'src/redux/slices/settings';
import { useDispatch } from 'react-redux';
import { getCompanyId } from 'src/redux/slices/authJwt';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_APP.report.profile
  }
];

const useStyles = makeStyles((theme) => ({
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8)
    }
  }
}));

// ----------------------------------------------------------------------

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const anchorRef = useRef(null);
  const companyIdFromLocalStorage = Number(getCompanyId());
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem('userData'));

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: homeFill,
      linkTo: userData.userType === 'CP' ? PATH_APP_CORP.root : PATH_APP.root
    },
    {
      label: 'Profile',
      icon: personFill,
      linkTo:
        userData.userType === 'CP'
          ? PATH_APP_CORP.report.corporateProfile
          : PATH_APP.report.profile
    }
  ];

  // console.log("MENU_OPTIONS:", MENU_OPTIONS);
  // console.log("MENU_OPTIONS:", userData.userType);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     if (isMountedRef.current) {
  //       history.push('/');
  //       handleClose();

  //       console.log("Account Logout Called:")
  //       // sessionStorage.clear();
  //       // localStorage.clear();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar('Unable to logout', { variant: 'error' });
  //   }
  // };

  const { commonAdmin_URL, commonSwitch_URL } = RSLUrl;
  const handleLogout1 = async () => {
    // const removeActiveTabParam = 'removeactivetab'; // Set your parameter and value here
    // const logoutUrl = http://localhost:3034/login?${removeActiveTabParam};
    var retrievedValue = localStorage.getItem('isLoggedIn');
    console.log('retrievedValue:', retrievedValue);
    if (retrievedValue && retrievedValue !== null && retrievedValue === '1') {
      console.log('Check1');
      localStorage.removeItem('isLoggedIn');
      const removeActiveTabParam = 'removeactivetab';
      const logoutParam = 'logout'; // Add your logout parameter here
      const logoutUrl = `${commonAdmin_URL}?${removeActiveTabParam}&${logoutParam}`;

      window.location.href = logoutUrl;
      logout();
    } else {
      console.log('Check2');
      localStorage.removeItem('isLoggedIn');
      history.push('/auth/manualLogin');
      handleClose();
      logout();
    }

    // try {
    //   logout();
    //   //  logout();

    //   window.location.href = `${process.env.REACT_APP_COMMON_ADMIN_PANEL}?${removeActiveTabParam}&${logoutParam}`;
    //   if (isMountedRef.current) {
    //     history.push('/');
    //     handleClose();

    //     console.log('Account Logout Called:');
    //   }
    // } catch (error) {
    //   console.error(error);
    //   enqueueSnackbar('Unable to logout', { variant: 'error' });
    // }
  };

  const handleLogout = () => {
    var retrievedValue = localStorage.getItem('isLoggedIn');
    var loginType = localStorage.getItem('loginType');
    // console.log('retrievedValue:', retrievedValue);
    if (retrievedValue && retrievedValue !== null && retrievedValue === '1') {
      console.log('Check1');
      // const removeActiveTabParam = 'removeactivetab';
      // const logoutParam = 'logout'; // Add your logout parameter here
      // const logoutUrl = `${process.env.REACT_APP_COMMON_ADMIN_PANEL}?${removeActiveTabParam}&${logoutParam}`;

      // window.location.href = logoutUrl;
      history.push('/');
      logout();
      // const obj = {
      //   primaryColor: '#00AB55',
      //   secondaryColor: '#007B55'
      // };
      // dispatch(getThemeInfo(obj));
      dispatch(getCompanyLoader(true));
      dispatch(getCompanyInfo({ domainName: window.location.hostname }));
      // dispatch(getCompanyInfo({ domainName: 'rjl.limor.us' }));
    } else if (loginType === 'A') {
      console.log('Check2');
      logout();
      // const obj = {
      //   primaryColor: '#00AB55',
      //   secondaryColor: '#007B55'
      // };
      // dispatch(getThemeInfo(obj));
      dispatch(getCompanyLoader(true));
      dispatch(getCompanyInfo({ domainName: window.location.hostname }));
      // dispatch(getCompanyInfo({ domainName: 'rjl.limor.us' }));
      history.push('/auth/manualLogin');
      handleClose();
    } else if (loginType === 'CP') {
      console.log('Check3');
      logout();
      // const obj = {
      //   primaryColor: '#00AB55',
      //   secondaryColor: '#007B55'
      // };
      // dispatch(getThemeInfo(obj));
      dispatch(getCompanyLoader(true));
      dispatch(getCompanyInfo({ domainName: window.location.hostname }));
      // dispatch(getCompanyInfo({ domainName: 'rjl.limor.us' }));
      history.push('/auth/corporateLogin');
      handleClose();
    } else if (loginType === 'WB') {
      history.push('/auth/webBookerLogin');
      handleClose();
    } else {
      console.log('Check4');
      logout();
      // const obj = {
      //   primaryColor: '#00AB55',
      //   secondaryColor: '#007B55'
      // };
      // dispatch(getThemeInfo(obj));
      dispatch(getCompanyLoader(true));
      dispatch(getCompanyInfo({ domainName: window.location.hostname }));
      // dispatch(getCompanyInfo({ domainName: 'rjl.limor.us' }));
      history.push('/auth/manualLogin');
      handleClose();
    }
  };
  // console.log("user:", userData)

  const handleSwitch = async () => {
    const logoutUrl = `${commonSwitch_URL}`;
    logout();
    window.location.href = logoutUrl;
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <MyAvatar userData={userData} />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userData.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* {MENU_OPTIONS.map((option) => ( */}

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        {companyIdFromLocalStorage === 0 && (
          <MenuItem
            onClick={handleSwitch}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={swapFill}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            Switch
          </MenuItem>
        )}

        {/* ))} */}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Account;
